//
//** ng-Select Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--select-base() {

  emma-select {
    display: block;

    .ngSelect-helpMessage-container{
      display: inline-block;
      position: relative;
      width: fit-content;

      &.fullwidth {
        display: block;
        width: auto;
      }

      .helpMessageAtError {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }

  @each $name, $color in $m--state-colors {
    .ng-select-btn-#{$name},
    .ng-select-btn-link-#{$name} {
      text-align: left;

      &.ng-select .ng-select-container {
        height: auto;
        // align-items: center;
        box-shadow: none;

        &.ng-select-container {
          z-index: 6;
        }

        font-weight: $btn-font-weight;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: $btn-border-width solid transparent;
        @include button-size-custom(
          $btn-padding-y,
          $btn-padding-x,
          $font-size-base,
          $btn-line-height,
          $btn-border-radius
        );
        @include transition($btn-transition);

        // Share hover and focus styles
        // @include hover-focus {
        //   text-decoration: none;
        //   box-shadow: none;
        // }

        // Disabled comes first so active can properly restyle
        &.disabled,
        &:disabled {
          opacity: $btn-disabled-opacity;
          box-shadow: none;
        }

        // Opinionated: add "hand" cursor to non-disabled .btn elements
        &:not([disabled]):not(.disabled) {
          cursor: pointer;
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active {
          background-image: none;
          @include box-shadow($btn-active-box-shadow);

          &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
          }
        }

        .ng-value-container {
          padding-left: 0;
          min-height: 17.5px;

          .ng-input {
            top: 8px;
            left: 3px !important;

            & > input {
              cursor: pointer;
            }
          }

          .ng-value {
            .content-icon {
              > .ng-value-description {
                font-size: 0.95rem;
                font-style: italic;
                padding-left: 8px;
                padding-right: 3px;
              }

              > i {
                font-size: 1rem;
                margin-right: 2px;

                &:last-of-type {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .ng-arrow-wrapper {
          padding-right: 0;
          text-align: center;
          width: 18px;

          > .ng-arrow {
            @include m-generate-arrow-emma-icon(down);
            top: 50%;
            position: absolute;
            margin-top: -(1.1rem * 0.5);
            font-size: 0.8rem;
            right: (array-get($m--forms, input, layout, self, size, default, padding, x) - 0.2);
            display: inline-block;
            border: none;
          }
        }
      }

      &.ng-select {
        display: inline-block;
        width: fit-content;

        &.ng-select-form-control {
          display: block;
          width: auto;
        }

        .ng-dropdown-panel {
          &.ng-dropdown-panel {
            z-index: 15;
          }

          .ng-dropdown-panel-items {
            display: flex;

            > .scrollable-content {
              position: relative !important;
            }
            > div {
              flex-grow: 1;
            }
            > div:first-child {
              flex-grow: 0;
            }

            .ng-option {
              font-size: 1rem;
              padding: 0.7rem 2rem 0.7rem 1.2rem;
              position: relative;

              .ng-option-label {
                display: inline-block;
              }

              .content-icon {
                > .ng-value-description {
                  font-size: 0.95rem;
                  font-style: italic;
                  padding-left: 8px;
                  padding-right: 3px;
                }

                > i {
                  font-size: 1rem;
                  margin-right: 2px;

                  &:last-of-type {
                    margin-right: 5px;
                  }
                }
              }

              .ng-option-removable {
                padding-right: 12px;
              }

              .ng-option-removeButton {
                position: absolute;
                right: 0;
                top: 3px;
              }

              &.ng-option-selected {
                text-decoration: none;
                position: relative;

                &::after {
                  position: absolute;
                  right: 7px;
                  top: calc(50% - 13px);
                  font-family: 'Line Awesome Free';
                  content: '\f00c';
                  text-decoration: inherit;
                  text-rendering: optimizeLegibility;
                  text-transform: none;
                  -moz-osx-font-smoothing: grayscale;
                  -webkit-font-smoothing: antialiased;
                  font-size: 1.2rem;
                  font-weight: 900;
                }
              }

              &.ng-option-removable {
                padding: 0.7rem 3rem 0.7rem 1.2rem;

                &.ng-option-selected {
                  &::after {
                    right: 31px;
                  }
                }
              }
            }

            .ng-optgroup {

              &.ng-option-selected {
                text-decoration: none;
                position: relative;

                &::after {
                  position: absolute;
                  right: 7px;
                  top: calc(50% - 13px);
                  font-family: 'Line Awesome Free';
                  content: '\f560';
                  text-decoration: inherit;
                  text-rendering: optimizeLegibility;
                  text-transform: none;
                  -moz-osx-font-smoothing: grayscale;
                  -webkit-font-smoothing: antialiased;
                  font-size: 1.2rem;
                  font-weight: 900;
                }

                > .ng-option-label  {
                  font-weight: 500;
                } 
              }
            } 
          }

          .ng-dropdown-footer {
            border: none;
            position: relative;
          }
        }

        &.ng-select-removable {
          .ng-dropdown-panel {
            .ng-dropdown-panel-items .ng-option {
              padding: 0.7rem 3rem 0.7rem 1.2rem;

              &.ng-option-selected {
                &::after {
                  right: 31px;
                }
              }
            }
          }
        }
      }

      &.ng-select.ng-select-xs .ng-select-container {
        padding: array-get($m--buttons, layout, custom, xs, self, padding, y)
          array-get($m--buttons, layout, custom, xs, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, xs, self, font-size);
      }

      &.ng-select.ng-select-sm .ng-select-container {
        padding: array-get($m--buttons, layout, custom, sm, self, padding, y)
          array-get($m--buttons, layout, custom, sm, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, sm, self, font-size);
      }

      &.ng-select.ng-select-lg .ng-select-container {
        padding: array-get($m--buttons, layout, custom, lg, self, padding, y)
          array-get($m--buttons, layout, custom, lg, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, lg, self, font-size);
      }

      &.ng-select.ng-select--pill .ng-select-container {
        @include border-radius($m-pill-radius);
      }

      &.ng-select.ng-select-drop-auto-width .ng-dropdown-panel {
        width: auto !important;
        min-width: 100%;
      }

      &.ng-select.ng-select-drop-panel-full-width .ng-dropdown-panel {
        width: 100% !important;
        min-width: 100%;

        > .ng-dropdown-panel-items {
          > div:not(:empty) {
            width: 100%;
          }
        }
      }

      &.ng-select.ng-select-drop-panel-right .ng-dropdown-panel {
        left: auto;
        right: 0;
      }

      &.ng-select-opened {
        &.ng-select .ng-select-container {
          .ng-arrow-wrapper {
            > .ng-arrow {
              @include m-generate-arrow-emma-icon(up);
            }
          }

          .ng-input > input {
            cursor: default;
          }
        }
      }

      &.ng-select-focused,
      &.ng-select-focused.ng-select-searchable,
      &.ng-select-focused:not(.ng-select-opened),
      &.ng-select-focused.ng-select-searchable:not(.ng-select-opened) {
        &.ng-select .ng-select-container {
          box-shadow: none;
        }
      }

      &.ng-select.ng-select-disabled {
        &.ng-select .ng-select-container,
        &.ng-select .ng-select-container:hover:not(:disabled),
        .ng-dropdown-panel-items,
        .ng-dropdown-footer {
          cursor: not-allowed;
          opacity: $btn-disabled-opacity;
          .ng-input > input {
            cursor: not-allowed;
          }
        }

        &.ng-select-multiple.ng-select-tags {

          .ng-select-container {

            .ng-value-container {

              .ng-value {
                cursor: not-allowed;
                pointer-events: none;
              }
            }
          }

          &.ng-select-tags-removable {

            .ng-value-container {
  
              .ng-value {
                max-height: 25px;
  
                .content-icon {
  
                  span {
                    line-height: 25px;
                    padding-left: 5px;
                  }

                  .ng-value-chip-close {
                    display: none;
                  }
                }
              }
            }
          }
        }  
      }

      &.ng-select.ng-select-multiple {
        .ng-select-container {
          .ng-placeholder {
            top: initial;
            padding-bottom: initial;
            padding-left: initial;
          }

          .ng-value-container {
            padding-top: 0;

            .ng-input {
              position: absolute;
              padding-bottom: 0;
              padding-left: 10px;
            }
          }
        }
      }

      &.ng-select.ng-select-multiple.ng-select-tags {
        .ng-select-container {
          padding-top: 0.65rem;
          padding-bottom: 0.65rem;

          &.ng-has-value {
            padding-top: 0.178rem;
            padding-bottom: 0.177rem;

            .ng-value-container {
              .ng-input {
                position: relative;
                padding-bottom: 0;
                padding-left: 3px;
                padding-right: 0;
                width: auto;
                top: 0;
                min-width: 25px;
              }
            }
          }

          .ng-value-container {
            align-content: center;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 2.5px;

            .ng-value {
              border: none;
              padding: 5px;
              border-radius: 3px;
              margin-top: 2.5px;
              margin-bottom: 2.5px;
              overflow: hidden;
              text-overflow: ellipsis;

              > div {
                display: inline;
              } 

              .ng-value-icon {
                border: none;
              }
            }
          }
        }

        &.ng-select-tags-removable {

          .ng-value-container {

            .ng-value {
              padding: 0;
              border-radius: 3px;

              .content-icon {
                // display: inline-block;

                span {
                  // display: inline-block;
                  padding: 5px 5px 5px 2px;
                }
                
                .ng-value-chip-close {
                  border: none;
                  font-weight: 300;
                  font-size: 0.9rem;
                  text-rendering: optimizeLegibility;
                  padding: 5px 5px 5px 7px;
                  max-height: 25px;
                }
              }
            }
          }
        } 
      }

      &.ng-select .ng-clear-wrapper {
        height: 15px;

        .ng-clear {
          font-weight: 100;
          color: get-state-color(emmadarkblue, base);
          opacity: 0.6;
          font-size: 16px;
          text-rendering: optimizeLegibility;
          position: absolute;
          top: 0;
          left: 2px;
        }

        &:hover {
          .ng-clear {
            opacity: 1;
          }
        }
      }

      &.ng-select.ng-select-filtered {
        .value-select {
          visibility: hidden;
        }

        .ng-value-container {
          .ng-placeholder {
            visibility: hidden;
            display: block;
          }
        }
      }
    }

    .ng-select-btn-outline-#{$name} {
      // @include button-outline-variant(array-get($color, base), array-get($color, inverse));
      font-size: 1rem;
    }

    @include tablet-and-mobile {
      .ng-select-btn-#{$name},
      .ng-select-btn-link-#{$name} {
        &.ng-select .ng-select-container {
          min-height: 34px;

          .ng-value-container {
            min-height: 16px;
          }
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--select-skin($skin) {
  @each $name, $color in $m--state-colors {
    .ng-select-btn-#{$name},
    .ng-select-btn-link-#{$name} {
      &.ng-select .ng-dropdown-panel {
        border: none;
        overflow: hidden;
        @include shadow(get-shadow($skin));
        @include border-radius(array-get($m--border-radius, dropdown));

        .ng-dropdown-panel-items {

          .ng-option {

            &.ng-option-marked {
              background: get-color($skin, panel, '-');

              .ng-option-label {
                color: get-color($skin, link);
              }
            }

            &.ng-option-selected {
              background: get-color($skin, panel);
              color: get-state-color(emmadarkblue, base);

              > span,
              .ng-option-label,
              .ng-option-label:hover {
                color: get-state-color(emmadarkblue, base);
              }

              &::after {
                color: get-color($skin, link);
              }
            }

            &.ng-option-disabled,
            &.ng-option-disabled:hover {
              color: $dropdown-link-disabled-color;
              background: #fff;
              cursor: default;
              opacity: 0.7;
            }

            .content-icon {
              > .ng-value-description {
                color: array-get($m--forms, input, skins, $skin, solid, color, placeholder);
              }
            }
          }

          .ng-optgroup {

            &.ng-option-marked {
              background: get-color($skin, panel, '-');
            }

            &.ng-option-selected {
              background: get-color($skin, panel, '-');

              &::after {
                color: get-state-color(emmadarkblue, base);
              }

              > .ng-option-label  {
                color: get-state-color(emmadarkblue, base);
              } 
            }
          }
        }

        .ng-dropdown-panel-items .ng-option,
        .ng-dropdown-panel-items .ng-option .ng-option-label {
          color: get-color($skin, regular);
          @include icons-size(array-get($m--bootstrap-extend-config, dropdown, icon, sizes));
          font-weight: $font-weight-normal;

          > i {
            color: get-color($skin, icon);
          }

          .ng-option:not(.ng-option-selected) &:hover,
          &:hover {
            text-decoration: none;
            color: get-color($skin, link);

            > i {
              color: get-color($skin, link);
            }
          }
        }

        .ng-dropdown-panel-items .ng-optgroup {
          font-weight: $font-weight-normal;
          color: $dropdown-link-disabled-color;
          font-size: $font-size-sm;
        }

        .ng-dropdown-footer {
          @include shadow(0 2px 15px 1px rgba($m--box-shadow-base-color, 0.2));
        }
      }

      &.ng-select {
        .ng-select-container .ng-value-container .ng-placeholder {
          color: array-get($m--forms, input, skins, $skin, default, color, placeholder);
        }
      }

      &.ng-select.ng-select-multiple.ng-select-tags {
        .ng-select-container {
          .ng-value-container {
            .ng-value {
              background-color: get-state-color(emmadarkblue, inverse);

              .ng-value-icon {
                background-color: transparent;

                &:hover {
                  color: get-state-color(danger, base);
                }
              }
            }
          }
        }


        &.ng-select-tags-removable {

          .ng-value-container {

            .ng-value {
              background-color: transparent;

              .content-icon {

                span {
                  background-color: get-state-color(emmadarkblue, inverse);
                }

                span.ng-label-disabled{
                  opacity: 0.7;
                }
                
                .ng-value-chip-close {
                  background-color: get-state-color(emmadarkblue, inverse);

                  &:hover {
                    @include color-variant-force-hover(array-get($color, base), array-get($color, base));
                    color: get-state-color(danger, base);
                  }
                }
              }
            }
          }
        } 

      }

      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {
        &.ng-select {
          .ng-select-container {
            border-color: array-get($m--forms, input, skins, $skin, ko, border, default);
            color: array-get($m--forms, input, skins, $skin, ko, bg, default);
          }
        }
      }
    }

    emma-select,
    emma-multitypeinput,
    emma-timeduration {
      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {
        .ng-select-btn-#{$name},
        .ng-select-btn-link-#{$name} {
          &.ng-select {
            .ng-select-container {
              border-color: array-get($m--forms, input, skins, $skin, ko, border, default);
              color: array-get($m--forms, input, skins, $skin, ko, bg, default);
            }
          }
        }
      }
    }

    .ng-select-btn-#{$name} {
      &.ng-select-focused,
      &.ng-select-focused.ng-select-searchable,
      &.ng-select-focused:not(.ng-select-opened),
      &.ng-select-focused.ng-select-searchable:not(.ng-select-opened) {
        &.ng-select .ng-select-container {
          border-color: array-get($m--forms, input, skins, $skin, solid, border, focus);
        }
      }

      &.ng-select.ng-select-disabled .ng-select-container,
      &.ng-select.ng-select-disabled .ng-select-container:hover:not(:disabled) {
        border-color: array-get($m--forms, input, skins, $skin, solid, border, disabled);
        /* color: array-get(
          $m--forms,
          input,
          skins,
          $skin,
          solid,
          color,
          disabled
        ); */
        background-color: array-get($m--forms, input, skins, $skin, solid, bg, disabled);
        cursor: not-allowed;
      }

      &.ng-select {
        .ng-select-container {
          @include color-variant(array-get($color, base), array-get($color, base));
          color: array-get($color, inverse);

          &.active,
          &.focus,
          &:focus,
          &:hover:not(:disabled) {
            color: array-get($color, inverse);
          }

          .ng-value-container {
            .ng-input,
            .ng-input > input {
              color: array-get($color, inverse);
            }
          }
        }

        &.ng-select-opened {
          &.ng-select .ng-select-container {
            @include color-variant-force-hover(array-get($color, base), array-get($color, base));
          }

          .ng-value-container {
            .ng-input,
            .ng-input > input {
              @include color-variant-force-hover-only-color(array-get($color, base), array-get($color, base));
            }
          }
        }
      }

      &.ng-select .ng-clear-wrapper {
        .ng-clear {
          color: array-get($color, inverse);
        }

        &:hover {
          .ng-clear {
            color: get-state-color(danger, base);
          }
        }
      }
    }

    .ng-select-btn-link-#{$name} {
      &.ng-select-focused,
      &.ng-select-focused.ng-select-searchable,
      &.ng-select-focused:not(.ng-select-opened),
      &.ng-select-focused.ng-select-searchable:not(.ng-select-opened) {
        &.ng-select .ng-select-container {
          border-color: transparent;
        }
      }

      &.ng-select.ng-select-disabled .ng-select-container,
      &.ng-select.ng-select-disabled .ng-select-container:hover:not(:disabled) {
        border-color: transparent;
        background-color: transparent;
        cursor: not-allowed;
      }

      &.ng-select {
        .ng-select-container {
          background-color: transparent;
          border-color: transparent;
          color: array-get($color, base);

          &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            top: inherit;
            left: 0;
            width: 0%;
            @include transition(width 0.3s ease);
          }

          &:hover {
            color: array-get($color, base);
            background-color: transparent;
            border-color: transparent;

            &:after {
              width: 100%;
              border-bottom: 1px solid darken(array-get($color, base), 10%);
              @include opacity(0.3);
            }
          }

          &.active,
          &.focus,
          &:focus,
          &:hover:not(:disabled) {
            color: darken(array-get($color, base), 7.5%);
          }

          .ng-value-container {
            .ng-input,
            .ng-input > input {
              color: array-get($color, base);
            }
          }
        }

        &.ng-select-opened {
          &.ng-select .ng-select-container {
            color: array-get($color, inverse);
            background-color: array-get($color, base);
            border-color: transparent;

            .ng-value-container {
              .ng-input,
              .ng-input > input {
                color: array-get($color, inverse);
              }
            }
          }

          .ng-clear-wrapper {
            .ng-clear {
              color: array-get($color, inverse);
            }
          }
        }
      }

      &.ng-select .ng-clear-wrapper {
        .ng-clear {
          color: array-get($color, base);
        }

        &:hover {
          .ng-clear {
            color: get-state-color(danger, base);
          }
        }
      }
    }
  }
}

@mixin color-variant(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  background-color: $background;
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  // @include hover {
  //   color: color-yiq($hover-background);
  //   background-color: $hover-background;
  //   border-color: $hover-border;
  // }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;
  }
}

@mixin color-variant-force-hover(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  color: color-yiq($hover-background);
  background-color: $hover-background;
  border-color: $hover-border;
}

@mixin color-variant-force-hover-only-color(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $active-background: darken($background, 10%)
) {
  color: color-yiq($hover-background);
}

//== Component Base
@include m-build-component--select-base();

//== Component Skin - Light
@include m-build-component--select-skin(light);
