//
//** User Welcome
//

//== General

@mixin build-people-profile() {

  .card-user-data {

    > i {
      color: get-color(light, muted);
    }

    > .card-text {

      > span {

        &.value {
          font-weight: get-font-weight(boldest);
        }
      }
    }
  }

  .data-block-container {

    .data-block {
  
      > i {
        color: get-color(light, muted);
      }
  
      .data-info {
  
        .data-line {
  
          .name {
            color: get-color(light, muted);
          }
        }
  
      }
    }
  }

}

//==== Generate Component ====//

//== Component General

  @include build-people-profile();
