//
//** User Welcome
//

//== General

@mixin build-userWelcome() {

  .userWelcome-container {
    padding: 15px;
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
  }

  .emmaCarousel-container {
    display: block;
    height: 50vh;

    .emmaCarousel-slider {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;

      .emmaCarousel-item {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;

        &.itemA {
          background-image: url('../../../assets/media/emma/img/carousel/emma-ambientA.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          animation: fadeA 20s infinite;
          -webkit-animation: fadeA 20s infinite;
        }

        &.itemB {
          background-image: url('../../../assets/media/emma/img/carousel/emma-ambientB.jpg');
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: cover;
          animation: fadeB 20s infinite;
          -webkit-animation: fadeB 20s infinite;
        }

        &.itemC {
          background-image: url('../../../assets/media/emma/img/carousel/emma-ambientC.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          animation: fadeC 20s infinite;
          -webkit-animation: fadeC 20s infinite;
        }

        &.itemD {
          background-image: url('../../../assets/media/emma/img/carousel/emma-ambientD.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          animation: fadeD 20s infinite;
          -webkit-animation: fadeD 20s infinite;
        }

      }
    }
  }

  @keyframes fadeA {
    0% { opacity: 1 }
    25% { opacity: 0 }
    50% { opacity: 0 }
    75% { opacity: 0 }
    100% { opacity: 1 }
  }

  @keyframes fadeB {
    0% { opacity: 0 }
    25% { opacity: 1 }
    50% { opacity: 0 }
    75% { opacity: 0 }
    100% { opacity: 0 }
  }

  @keyframes fadeC {
    0% { opacity: 0 }
    25% { opacity: 0 }
    50% { opacity: 1 }
    75% { opacity: 0 }
    100% { opacity: 0 }
  }

  @keyframes fadeD {
    0% { opacity: 0 }
    25% { opacity: 0 }
    50% { opacity: 0 }
    75% { opacity: 1 }
    100% { opacity: 0 }
  }
}

//==== Generate Component ====//

//== Component General

  @include build-userWelcome();
