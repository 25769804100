//
//** Override The Base Framework Config For This Demo
//

//== Example(reduce font size from 14px to 13px globally for desktop mode only)

//== The body element font settings
/**
$m--root-font: (
    size: (
        desktop: 13px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 300, 
    color: (
        light: #333333, 
        dark: #efefef
    )
);
*/

//
//** Override The Base Framework Config For This Demo
//

//== The body element font settings
$m--root-font: (
    size: (
        desktop: 14px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 400, 
    color: (
        light: #0a2c33, 
        dark: #efefef
    )
);

//== Font families
$m--font-families: (
    regular: 'Rubik',  
    heading: 'Rubik'
);

//== Brand Color
$m--brand-color: #00a263;
$m--brand-inverse-color: #ffffff;

$m--state-colors: (
    // metronic states
    brand: (
        base: $m--brand-color,
        inverse: $m--brand-inverse-color
    ),
    metal: (
        base: lighten(#ebedf2, 3%),
        inverse: #0a2c33
    ),  
    light: (
        base: #ffffff,
        inverse: #282a3c 
    ),
    accent: (
        base: #00c5dc,
        inverse: #ffffff 
    ),
    focus: (
        base: #9816f4,
        inverse: #ffffff 
    ),

    // bootstrap states
    primary: (
        base: #5867dd,
        inverse: #ffffff
    ),
    success: (
        base: #00a263,
        inverse: #ffffff
    ),
    info: (
        base: #0a2c33,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #ffffff
    ),
    danger: (
        base: #f4516c,
        inverse: #ffffff
    ),

    // emma states
    emmagreen: (
        base: #00a263,
        inverse: #ffffff
    ),
    emmablue: (
        base: #12849f,
        inverse: #ffffff
    ),
    emmadarkblue: (
        base: #0a2c33,
        inverse: #ffffff
    ),
    altgreen: (
        base: #01e08a,
        inverse: #0a2c33
    ),
    altpink: (
        base: #ff1981,
        inverse: #ffffff
    ),
    altblue: (
        base: #18b4d9,
        inverse: #ffffff
    ),
    altorange: (
        base: #ff5719,
        inverse: #ffffff
    ),
    altsoftorange: (
        base: #ffc778,
        inverse: #ffffff
    ),
    altyellow: (
        base: #ffea76,
        inverse: #0a2c33
    ),
    altviolet: (
        base: #b678fe,
        inverse: #ffffff
    ),
    altdarkblue: (
        base: #11515E,
        inverse: #ffffff
    )
)!default;

//== General typography font colors
$m--typography-colors: (
    // light skin
    light: (
        // regular text color
        regular: (
            lighten5: lighten(#0a2c33, 30%), // access to this color value calling get-color function as get-color(light, regular, -----) in SASS code
            lighten4: lighten(#0a2c33, 25%), // access to this color value calling get-color function as get-color(light, regular, ----) in SASS code
            lighten3: lighten(#0a2c33, 20%), // access to this color value calling get-color function as get-color(light, regular, ---) in SASS code
            lighten2: lighten(#0a2c33, 15%), // access to this color value calling get-color function as get-color(light, regular, --) in SASS code
            lighten1: lighten(#0a2c33, 10%), // access to this color value calling get-color function as get-color(light, regular, -) in SASS code
            base:     #0a2c33,               // access to this color value calling get-color function as get-color(light, regular) in SASS code
            darken1:  darken(#0a2c33, 10%),  // access to this color value calling get-color function as get-color(light, regular, +) in SASS code
            darken2:  darken(#0a2c33, 15%),  // access to this color value calling get-color function as get-color(light, regular, ++) in SASS code
            darken3:  darken(#0a2c33, 20%),  // access to this color value calling get-color function as get-color(light, regular, +++) in SASS code
            darken4:  darken(#0a2c33, 25%),  // access to this color value calling get-color function as get-color(light, regular, ++++) in SASS code
            darken5:  darken(#0a2c33, 30%)   // access to this color value calling get-color function as get-color(light, regular, +++++) in SASS code 
        ),

        // link color
        link: (
            lighten5: lighten(#00a263, 30%), // access to this color value calling get-color function as get-color(light, link, -----) in SASS code
            lighten4: lighten(#00a263, 25%), // access to this color value calling get-color function as get-color(light, link, ----) in SASS code
            lighten3: lighten(#00a263, 20%), // access to this color value calling get-color function as get-color(light, link, ---) in SASS code
            lighten2: lighten(#00a263, 15%), // access to this color value calling get-color function as get-color(light, link, --) in SASS code
            lighten1: lighten(#00a263, 10%), // access to this color value calling get-color function as get-color(light, link, -) in SASS code
            base:     #00a263, 
            darken1:  darken(#00a263, 10%),  // access to this color value calling get-color function as get-color(light, link, +++++) in SASS code
            darken2:  darken(#00a263, 15%),  // access to this color value calling get-color function as get-color(light, link, ++++) in SASS code
            darken3:  darken(#00a263, 20%),  // access to this color value calling get-color function as get-color(light, link, +++) in SASS code
            darken4:  darken(#00a263, 25%),  // access to this color value calling get-color function as get-color(light, link, ++) in SASS code
            darken5:  darken(#00a263, 30%)   // access to this color value calling get-color function as get-color(light, link, +) in SASS code
        ),

        // disabled color
        disabled: (
            lighten5: lighten(#f4f5f8, 7%), // access to this color value calling get-color function as get-color(light, disabled, -----) in SASS code
            lighten4: lighten(#f4f5f8, 6%), // access to this color value calling get-color function as get-color(light, disabled, ----) in SASS code
            lighten3: lighten(#f4f5f8, 5%), // access to this color value calling get-color function as get-color(light, disabled, ---) in SASS code
            lighten2: lighten(#f4f5f8, 4%), // access to this color value calling get-color function as get-color(light, disabled, --) in SASS code
            lighten1: lighten(#f4f5f8, 3%), // access to this color value calling get-color function as get-color(light, disabled, -) in SASS code
            base:     #f4f5f8, 
            darken1:  desaturate(darken(#f4f5f8, 5), 10),  // access to this color value calling get-color function as get-color(light, disabled, +++++) in SASS code
            darken2:  desaturate(darken(#f4f5f8, 10), 10),  // access to this color value calling get-color function as get-color(light, disabled, ++++) in SASS code
            darken3:  desaturate(darken(#f4f5f8, 15), 10),  // access to this color value calling get-color function as get-color(light, disabled, +++) in SASS code
            darken4:  desaturate(darken(#f4f5f8, 20), 10),  // access to this color value calling get-color function as get-color(light, disabled, ++) in SASS code
            darken5:  desaturate(darken(#f4f5f8, 25), 10)   // access to this color value calling get-color function as get-color(light, disabled, +) in SASS code
        ),

        // icon color
        icon: (
            lighten5: lighten(#b8bece, 25%),
            lighten4: lighten(#b8bece, 20%),
            lighten3: lighten(#b8bece, 15%),
            lighten2: lighten(#b8bece, 10%),
            lighten1: lighten(#b8bece, 5%),
            base:     #b8bece, 
            darken1:  darken(#b8bece, 5%),
            darken2:  darken(#b8bece, 10%),
            darken3:  darken(#b8bece, 15%),
            darken4:  darken(#b8bece, 20%),
            darken5:  darken(#b8bece, 25%)
        ),

        // muted text color
        muted: (
            lighten5: lighten(#afb2c1, 30%),
            lighten4: lighten(#afb2c1, 25%),
            lighten3: lighten(#afb2c1, 20%),
            lighten2: lighten(#afb2c1, 15%),
            lighten1: lighten(#afb2c1, 10%),
            base:     #afb2c1, 
            darken1:  darken(#afb2c1, 10%),
            darken2:  darken(#afb2c1, 15%),
            darken3:  darken(#afb2c1, 20%),
            darken4:  darken(#afb2c1, 25%),
            darken5:  darken(#afb2c1, 30%)
        ), 

        // panel(border colors)
        panel: (
            lighten5: lighten(#ebedf2, 7%),
            lighten4: lighten(#ebedf2, 6%),
            lighten3: lighten(#ebedf2, 5%),
            lighten2: lighten(#ebedf2, 4%),
            lighten1: lighten(#ebedf2, 3%),
            base:     #ebedf2, 
            darken1:  darken(#ebedf2, 3%),
            darken2:  darken(#ebedf2, 4%),
            darken3:  darken(#ebedf2, 15%),
            darken4:  darken(#ebedf2, 20%),
            darken5:  darken(#ebedf2, 25%),
        ),

        // box(background colors)
        box: (
            lighten5: lighten(#ffffff, 6%),
            lighten4: lighten(#ffffff, 5%),
            lighten3: lighten(#ffffff, 4%),
            lighten2: lighten(#ffffff, 3%),
            lighten1: lighten(#ffffff, 2%),
            base:     #ffffff, 
            darken1:  darken(#ffffff, 2%),
            darken2:  darken(#ffffff, 3%),
            darken3:  darken(#ffffff, 4%),
            darken4:  darken(#ffffff, 5%),
            darken5:  darken(#ffffff, 6%),
        )
    ),

    // dark skin
    dark: (
        // regular text color
        regular: (
            lighten5: lighten(#ffffff, 25%), // access to this color value calling get-color function as get-color(dark, regular, +++++) in SASS code
            lighten4: lighten(#ffffff, 20%), // access to this color value calling get-color function as get-color(dark, regular, ++++) in SASS code
            lighten3: lighten(#ffffff, 15%), // access to this color value calling get-color function as get-color(dark, regular, +++) in SASS code
            lighten2: lighten(#ffffff, 10%), // access to this color value calling get-color function as get-color(dark, regular, ++) in SASS code
            lighten1: lighten(#ffffff, 5%), // access to this color value calling get-color function as get-color(dark, regular, +) in SASS code
            base:     #ffffff,               // access to this color value calling get-color function as get-color(dark, regular) in SASS code
            darken1:  darken(#ffffff, 5%),  // access to this color value calling get-color function as get-color(dark, regular, -) in SASS code
            darken2:  darken(#ffffff, 10%),  // access to this color value calling get-color function as get-color(dark, regular, --) in SASS code
            darken3:  darken(#ffffff, 15%),  // access to this color value calling get-color function as get-color(dark, regular, ---) in SASS code
            darken4:  darken(#ffffff, 20%),  // access to this color value calling get-color function as get-color(dark, regular, ----) in SASS code
            darken5:  darken(#ffffff, 25%)   // access to this color value calling get-color function as get-color(dark, regular, -----) in SASS code 
        ),

        // link color
        link: (
            lighten5: lighten(#00a263, 30%), // access to this color value calling get-color function as get-color(dark, link, +++++) in SASS code
            lighten4: lighten(#00a263, 25%), // access to this color value calling get-color function as get-color(dark, link, ++++) in SASS code
            lighten3: lighten(#00a263, 20%), // access to this color value calling get-color function as get-color(dark, link, +++) in SASS code
            lighten2: lighten(#00a263, 15%), // access to this color value calling get-color function as get-color(dark, link, ++) in SASS code
            lighten1: lighten(#00a263, 10%), // access to this color value calling get-color function as get-color(dark, link, +) in SASS code
            base:     #00a263,               // access to this color value calling get-color function as get-color(dark, link) in SASS code
            darken1:  darken(#00a263, 10%),  // access to this color value calling get-color function as get-color(dark, link, -) in SASS code
            darken2:  darken(#00a263, 15%),  // access to this color value calling get-color function as get-color(dark, link, --) in SASS code
            darken3:  darken(#00a263, 20%),  // access to this color value calling get-color function as get-color(dark, link, ---) in SASS code
            darken4:  darken(#00a263, 25%),  // access to this color value calling get-color function as get-color(dark, link, ----) in SASS code
            darken5:  darken(#00a263, 30%)   // access to this color value calling get-color function as get-color(dark, link, -----) in SASS code 
        ),

        // icon color
        icon: (
            lighten5: lighten(#dfdff1, 25%),
            lighten4: lighten(#dfdff1, 20%),
            lighten3: lighten(#dfdff1, 15%),
            lighten2: lighten(#dfdff1, 10%),
            lighten1: lighten(#dfdff1, 5%),
            base:     #dfdff1, 
            darken1:  darken(#dfdff1, 5%),
            darken2:  darken(#dfdff1, 10%),
            darken3:  darken(#dfdff1, 15%),
            darken4:  darken(#dfdff1, 20%),
            darken5:  darken(#dfdff1, 25%)
        ),

        // muted text color
        muted: (
            lighten5: lighten(#c9c9e0, 30%),
            lighten4: lighten(#c9c9e0, 25%),
            lighten3: lighten(#c9c9e0, 20%),
            lighten2: lighten(#c9c9e0, 15%),
            lighten1: lighten(#c9c9e0, 10%),
            base:     #c9c9e0, 
            darken1:  darken(#c9c9e0, 10%),
            darken2:  darken(#c9c9e0, 15%),
            darken3:  darken(#c9c9e0, 20%),
            darken4:  darken(#c9c9e0, 25%),
            darken5:  darken(#c9c9e0, 30%)
        ), 

        // panel(border colors)
        panel: (
            lighten5: lighten(#a1a4b1, 7%),
            lighten4: lighten(#a1a4b1, 6%),
            lighten3: lighten(#a1a4b1, 5%),
            lighten2: lighten(#a1a4b1, 4%),
            lighten1: lighten(#a1a4b1, 3%),
            base:     #a1a4b1, 
            darken1:  darken(#a1a4b1, 3%),
            darken2:  darken(#a1a4b1, 4%),
            darken3:  darken(#a1a4b1, 15%),
            darken4:  darken(#a1a4b1, 20%),
            darken5:  darken(#a1a4b1, 25%),
        ),

        // box(background colors)
        box: (
            lighten5: lighten(#2c2e2e, 6%),
            lighten4: lighten(#2c2e2e, 5%),
            lighten3: lighten(#2c2e2e, 4%),
            lighten2: lighten(#2c2e2e, 3%),
            lighten1: lighten(#2c2e2e, 2%),
            base:     #0a2c33, 
            darken1:  darken(#0a2c33, 2%),
            darken2:  darken(#0a2c33, 3%),
            darken3:  darken(#0a2c33, 4%),
            darken4:  darken(#0a2c33, 5%),
            darken5:  darken(#0a2c33, 6%),
        )
    )
)!default;  