//
// ** Link Component
//

$m-pill-radius: 60px;

//== Component Base
@mixin m-build-component--buttons-base() {
  // base button
  .btn {
    font-family: array-get($m--font-families, regular);
    @include shadow(none !important);

    // icon
    > i {
      line-height: 0;
      vertical-align: middle;
    }

    // remove button default focus shadow
    &:hover,
    &.active,
    &:focus {
      @include shadow(none);
    }

    // force cursor: pointer for button tags
    @if ($m--btn-force-hover-pointer==true) {
      cursor: pointer;
    }

    // pill style
    &.m-btn--pill {
      @include border-radius($m-pill-radius);
    }

    // square style
    &.m-btn--square {
      @include border-radius(0, '!important');
    }

    // secondary button air style
    &.m-btn--air.btn-secondary {
      border-color: #ffffff !important;

      &:hover {
        border-color: #ffffff !important;
      }
    }

    &.m-btn--pseudo-button {
      cursor: auto;
    }

    // Wide buttons
    &.m-btn--wide {
      padding-left: 2 * array-get($m--buttons, layout, default, default, self, padding, x);
      padding-right: 2 * array-get($m--buttons, layout, default, default, self, padding, x);

      &.btn-xs {
        padding-left: 2 * array-get($m--buttons, layout, default, xs, self, padding, x);
        padding-right: 2 * array-get($m--buttons, layout, default, xs, self, padding, x);
      }

      &.btn-sm {
        padding-left: 2 * array-get($m--buttons, layout, default, sm, self, padding, x);
        padding-right: 2 * array-get($m--buttons, layout, default, sm, self, padding, x);
      }

      &.btn-lg {
        padding-left: 2 * array-get($m--buttons, layout, default, lg, self, padding, x);
        padding-right: 2 * array-get($m--buttons, layout, default, lg, self, padding, x);
      }
    }

    // Custom buttons
    &.m-btn--custom {
      padding: array-get($m--buttons, layout, custom, default, self, padding, y)
        array-get($m--buttons, layout, custom, default, self, padding, x);
      font-size: array-get($m--buttons, layout, custom, default, self, font-size);
      font-weight: get-font-weight(bold);
      font-family: #{array-get($m--font-families, regular)};

      &.btn-xs {
        padding: array-get($m--buttons, layout, custom, xs, self, padding, y)
          array-get($m--buttons, layout, custom, xs, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, xs, self, font-size);
      }

      &.btn-sm {
        padding: array-get($m--buttons, layout, custom, sm, self, padding, y)
          array-get($m--buttons, layout, custom, sm, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, sm, self, font-size);
      }

      &.btn-lg {
        padding: array-get($m--buttons, layout, custom, lg, self, padding, y)
          array-get($m--buttons, layout, custom, lg, self, padding, x);
        font-size: array-get($m--buttons, layout, custom, lg, self, font-size);
      }
    }

    // label states
    @each $name, $color in $m--state-colors {
      &.m-btn--label-#{$name} {
        color: array-get($color, base);
      }
    }

    // custom buttons font styles
    &.m-btn--uppercase {
      text-transform: uppercase;
    }

    // button font helpers
    &.m-btn--regular {
      font-weight: get-font-weight(regular);
    }

    &.m-btn--bold {
      font-weight: get-font-weight(bold);
    }

    &.m-btn--bolder {
      font-weight: get-font-weight(bolder);
    }

    &.m-btn--boldest {
      font-weight: get-font-weight(boldest);
    }

    &.m-btn--sm {
      font-size: get-font-size(regular, '-');
    }

    &.no-focus-btn.no-focus-btn {
      &.focus:not(:hover),
      &:focus:not(:hover) {
        border-color: transparent !important;
        background-color: transparent !important;
        color: inherit !important;
      }
    }

    // icon button
    &.m-btn--icon {
      // label + icon
      > span {
        display: table;

        > i {
          display: table-cell;
          vertical-align: middle;
          line-height: 0;
        }

        @include icons-size(array-get($m--buttons, layout, default, default, icon, font-size));

        > span {
          padding-left: 0.5em;
          display: table-cell;
          vertical-align: middle;
        }
      }

      &.m-btn--icon-right {
        > span {
          > span {
            padding-left: 0;
            padding-right: 0.6em;
          }
        }
      }

      // button sizing

      &.btn-lg {
        > span {
          @include icons-size(array-get($m--buttons, layout, default, lg, icon, font-size));
        }
      }

      &.btn-sm {
        > span {
          @include icons-size(array-get($m--buttons, layout, default, sm, icon, font-size));
        }
      }

      &.btn-xs {
        > span {
          @include icons-size(array-get($m--buttons, layout, default, xs, icon, font-size));
        }
      }

      // icon only without label
      &.m-btn--icon-only {
        display: inline-block;
        position: relative;
        padding: 0 !important;
        width: array-get($m--buttons, layout, default, default, icon, icon-only-size);
        height: array-get($m--buttons, layout, default, default, icon, icon-only-size);

        &.m-btn--help-label,
        &.m-btn--copy-url,
        &.m-btn--visibility-btn {
          width: array-get($m--buttons, layout, default, default, icon, icon-only-help);
          height: array-get($m--buttons, layout, default, default, icon, icon-only-help);
          margin-top: -1px;
        }

        &.m-btn--copy-url,
        &.m-btn--visibility-btn {
          > i {
            font-size: get-font-size(icon, '---');
          }
        }

        > i {
          line-height: 0;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }

        @include icons-size(array-get($m--buttons, layout, default, default, icon, font-size));

        &.th-btn-pill {
          @include border-radius(100%);
        }

        &.btn-lg {
          width: array-get($m--buttons, layout, default, lg, icon, icon-only-size);
          height: array-get($m--buttons, layout, default, lg, icon, icon-only-size);

          @include icons-size(array-get($m--buttons, layout, default, lg, icon, font-size));
        }

        &.btn-sm {
          width: array-get($m--buttons, layout, default, sm, icon, icon-only-size);
          height: array-get($m--buttons, layout, default, sm, icon, icon-only-size);

          @include icons-size(array-get($m--buttons, layout, default, sm, icon, font-size));
        }

        &.btn-xs {
          width: array-get($m--buttons, layout, default, xs, icon, icon-only-size);
          height: array-get($m--buttons, layout, default, xs, icon, icon-only-size);

          @include icons-size(array-get($m--buttons, layout, default, xs, icon, font-size));
        }
      }

      // metronic style buttons
      &.m-btn--custom {
        > span {
          @include icons-size(array-get($m--buttons, layout, custom, default, icon, font-size));
        }

        &.btn-lg {
          > span {
            @include icons-size(array-get($m--buttons, layout, custom, lg, icon, font-size));
          }
        }

        &.btn-sm {
          > span {
            @include icons-size(array-get($m--buttons, layout, custom, sm, icon, font-size));
          }
        }

        &.btn-xs {
          > span {
            @include icons-size(array-get($m--buttons, layout, custom, xs, icon, font-size));
          }
        }

        &.m-btn--icon-only {
          width: array-get($m--buttons, layout, custom, default, icon, icon-only-size);
          height: array-get($m--buttons, layout, custom, default, icon, icon-only-size);

          &.btn-lg {
            width: array-get($m--buttons, layout, custom, lg, icon, icon-only-size);
            height: array-get($m--buttons, layout, custom, lg, icon, icon-only-size);
          }

          &.btn-sm {
            width: array-get($m--buttons, layout, custom, sm, icon, icon-only-size);
            height: array-get($m--buttons, layout, custom, sm, icon, icon-only-size);
          }

          &.btn-xs {
            width: array-get($m--buttons, layout, custom, xs, icon, icon-only-size);
            height: array-get($m--buttons, layout, custom, xs, icon, icon-only-size);
          }
        }
      }

      // block btn
      &.btn-block {
        > span {
          text-align: center;
          margin: 0 auto;
        }
      }

      // dropdown btn
      &.dropdown-toggle {
        > span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    // base icons
    @include icons-size(array-get($m--buttons, layout, default, default, icon, font-size));

    &.btn-xs {
      @include icons-size(array-get($m--buttons, layout, default, xs, icon, font-size));
      @include button-size-custom(
        $btn-padding-y-xs,
        $btn-padding-x-xs,
        $font-size-xs,
        $btn-line-height,
        $btn-border-radius-xs
      );
    }

    &.btn-sm {
      @include icons-size(array-get($m--buttons, layout, default, sm, icon, font-size));
    }

    &.btn-lg {
      @include icons-size(array-get($m--buttons, layout, default, lg, icon, font-size));
    }

    // link button
    .m-btn.m-btn--link {
      &:focus,
      &:active {
        outline: none;
        @include shadow(none);
      }
    }

    // outline buttons
    .m-btn.m-btn--outline-2x {
      border-width: 2px;
    }

    // button group
    .btn-group {
      &.m-btn-group--pill:not(.btn-group-vertical) {
        .btn {
          &:first-child {
            @include border-radius($m-pill-radius 0 0 $m-pill-radius);
          }

          &:last-child {
            @include border-radius(0 $m-pill-radius $m-pill-radius 0);
          }

          // pill mode
          &.m-btn--pill-last {
            @include border-radius(0 $m-pill-radius $m-pill-radius 0);
          }

          &.m-btn--pill-first {
            @include border-radius($m-pill-radius 0 0 $m-pill-radius);
          }
        }
      }

      &.m-btn-group--air {
        @include border-radius(0.25rem);
      }

      &.m-btn-group--air.m-btn-group--pill {
        @include border-radius($m-pill-radius);
      }

      &.btn-group-box {
        @include border-radius(0.35rem);
        padding: array-get($m--buttons, layout, custom, sm, self, padding, y);
        flex-wrap: wrap;
        justify-content: center;

        > .btn.btn,
        > .m-btn.m-btn {
          @include border-radius(0.25rem);
        }

        > .btn:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    // vertical button group
    .btn-group-vertical {
      .m-btn--pill-last {
        @include border-radius(0 0 $m-pill-radius $m-pill-radius);
      }

      .m-btn--pill-first {
        @include border-radius($m-pill-radius $m-pill-radius 0 0);
      }
    }

    .btn-group-xs > .btn {
      @extend .btn-xs;
    }

    .btn-xs + .dropdown-toggle-split {
      padding-right: $btn-padding-x-xs * 0.75;
      padding-left: $btn-padding-x-xs * 0.75;
    }

    // air buttons
    @each $name, $color in $m--state-colors {
      &.btn-outline-#{$name}.m-btn--air,
      &.btn-#{$name}.m-btn--air,
      &.m-btn--gradient-from-#{$name}.m-btn--air {
        @include shadow(0px 5px 10px 2px rgba(array-get($color, base), 0.19), !important);

        &.focus,
        &:focus,
        &:hover {
          @include shadow(0px 5px 10px 2px rgba(array-get($color, base), 0.36), !important);
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--buttons-skin() {
  $skin: get-default-skin();

  .btn {
    // standart buttons
    &.btn-default,
    &.btn-secondary {
      background: white;
      border-color: array-get($m--forms, input, skins, $skin, default, border, default);

      i {
        color: get-color($skin, regular, '-');
      }

      &:hover:not(:disabled),
      &.active,
      &:active,
      &:focus,
      .show > &.dropdown-toggle {
        border-color: array-get($m--forms, input, skins, $skin, default, border, default);
        background-color: get-color($skin, panel, '-');
      }

      &.disabled,
      &:disabled {
        border-color: array-get($m--forms, input, skins, $skin, default, border, disabled);
        cursor: not-allowed;
      }
    }

    // air buttons
    &.m-btn--air {
      &.btn-default,
      &.btn-secondary {
        @include shadow(array-get($m--buttons, skins, $skin, air, box-shadow, default), !important);
        border-color: #ffffff !important;

        &.focus,
        &:focus,
        &:hover:not(:disabled):not(.active) {
          @include shadow(array-get($m--buttons, skins, $skin, air, box-shadow, hover), !important);
          background: get-brand-color() !important;
          color: get-brand-inverse-color(inverse) !important;
          border-color: get-brand-color() !important;

          i {
            color: get-brand-inverse-color(inverse) !important;
          }
        }
      }
    }

    // hover buttons
    @each $name, $color in $m--state-colors {
      // standart buttons
      &.m-btn--hover-#{$name} {
        &:not(.btn-secondary):not(.btn-outline-light) {
          background: none;

          color: get-color($skin, regular);

          i {
            color: get-color($skin, regular);
          }
        }

        &:hover,
        &.active,
        &:active,
        &:focus,
        .show > &.dropdown-toggle {
          border-color: array-get($color, base) !important;
          color: array-get($color, inverse) !important;

          i {
            color: array-get($color, inverse) !important;
          }

          background-color: array-get($color, base) !important;
        }
      }

      // gradient buttons
      &.m-btn--gradient-from-#{$name} {
        color: array-get($color, inverse);
        border: 0 !important;

        @each $name2, $color2 in $m--state-colors {
          &.m-btn--gradient-to-#{$name2} {
            @include gradient-bg(
              array-get($color, base),
              135deg,
              array-get($color, base) 30%,
              array-get($color2, base) 100%
            );

            &:hover,
            &.active,
            &:active,
            &:focus,
            .show > &.dropdown-toggle {
              @include gradient-bg(
                darken(array-get($color, base), 6%),
                135deg,
                darken(array-get($color, base), 6%) 30%,
                darken(array-get($color2, base), 6%) 100%
              );
            }
          }
        }
      }

      &.btn-#{$name} {
        color: array-get($color, inverse);

        &.active,
        &.focus,
        &:focus,
        &:hover:not(:disabled) {
          color: array-get($color, inverse) !important;
        }

        &.disabled,
        &:disabled {
          cursor: not-allowed;
        }
      }

      &.btn-inverse-#{$name} {
        color: array-get($color, base);
        background-color: array-get($color, inverse);

        &.active,
        &.focus,
        &:focus,
        &:hover:not(:disabled) {
          color: array-get($color, base) !important;
          background-color: darken(array-get($color, inverse), 7.5%) !important;
        }

        &.disabled,
        &:disabled {
          cursor: not-allowed;
        }
      }

      &.btn-outline-#{$name} {
        color: get-state-color(emmadarkblue, base);

        &.active,
        &.focus,
        &:focus,
        &:hover:not(:disabled) {
          color: array-get($color, inverse) !important;
        }

        &.disabled,
        &:disabled {
          cursor: not-allowed;
        }
      }

      &.m-btn-link-#{$name} {
        color: array-get($color, base);
        background-color: transparent;
        border-color: transparent;
        position: relative;

        &:after {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          top: 1rem;
          left: 0;
          width: 0%;
          @include transition(width 0.3s ease);
        }

        &.active,
        &.focus,
        &:focus,
        &:hover:not(:disabled) {
          color: darken(array-get($color, base), 7.5%);

          &:after {
            width: 100%;
            border-bottom: 1px solid darken(array-get($color, base), 10%);
            @include opacity(0.3);
          }
        }

        &.disabled,
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  // air button group
  .btn-group {
    &.m-btn-group--air {
      @include shadow(array-get($m--buttons, skins, $skin, air, box-shadow, default), !important);

      .btn-default,
      .btn-secondary {
        border-color: #ffffff !important;

        &.focus,
        &:focus,
        &:hover:not(:disabled) {
          background: #ffffff !important;
          @include shadow(array-get($m--buttons, skins, $skin, air, box-shadow, default), !important);
        }
      }
    }

    &.btn-group-box {
      @each $name, $color in $m--state-colors {
        // standart buttons
        &.btn-group-#{$name} {
          background-color: array-get($color, base);
        }
      }
    }
  }
}

//== Build Component Base
@include m-build-component--buttons-base();

//== Build Component Skin
@include m-build-component--buttons-skin();
