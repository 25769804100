//
//** EMMA Journey Designer
//

//== General

@mixin build-journey() {
  journeys-detail {
    .journey-detail-portlet {
      .m-portlet {
        position: unset;
      }

      .emma-portlet-head-tools-switch {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  emma-journey-designer {
    .emma-journey-designer {
      display: grid;
      grid-template-columns: 20% 80%;
      column-gap: 15px;
      padding-top: 15px;

      .emma-journey-designer-left {
        // @include shadow(15px 0px 15px -10px rgba($m--box-shadow-base-color, 0.2));
        padding-right: 15px;

        .emma-journey-designer-left-container {
          display: block;
          position: -webkit-sticky;
          position: sticky;
          z-index: 12;

          @include transition(array-get($m-config-header, header, self, transition, general));

          top: 140px;

          @at-root .m-header--fixed.m-header--minimize-on & {
            @include transition(array-get($m-config-header, header, self, transition, hide));

            top: 75px;
          }
        }

        .emma-journey-designer-left-title {
          font-weight: 500;
          font-size: 1.1rem;
        }

        .emma-journey-designer-palette {
          .emma-journey-designer-row-blocks {
            display: flex;
            flex-wrap: wrap;
          }

          .emma-journey-designer-section {
            margin-bottom: 15px;

            &.disabled {
              opacity: 0.5;

              .block {
                &.hover.hover {
                  cursor: not-allowed;

                  .block-shape {
                    stroke-width: 0;
                  }
                }
              }

              div.block.disabled {
                opacity: 1;
              }
            }

            div.block.disabled {
              opacity: 0.5;

              svg.hover.hover {
                cursor: not-allowed;

                .block-shape {
                  stroke-width: 0;
                }
              }
            }
          }
        }
      }

      .emma-journey-designer-left .emma-journey-designer-palette,
      .emma-journey-designer-main .emma-journey-designer-grid {
        .block {
          overflow: visible;

          svg {
            overflow: visible;
          }

          .block-label {
            overflow: visible;
            text-anchor: middle;

            tspan.label {
              font-weight: bold;
            }
          }

          .block-stats {
            overflow: visible;
            text-anchor: middle;

            tspan {
              font-weight: bold;
            }
          }

          .block-shape {
            stroke-width: 0;
            stroke-dasharray: 0;
            transition: all 0.2s ease-in-out;
          }

          &.hover {
            cursor: grab;

            .block-shape {
              stroke-width: 10px;
            }
          }

          &.selected {
            .block-shape {
              stroke-width: 2px;
            }
          }

          &.dragged {
            opacity: 0.6;

            .block-shape {
              stroke-width: 2px;
              stroke-dasharray: 5, 5;
            }
          }
        }

        .path-group {
          text:not(.block-label) {
            tspan.label {
              font-size: 12px;
            }
          }
        }

        svg {
          overflow: visible;
          padding-bottom: 1em;
        }
      }

      &.readonly {
        display: block;
      }

      .dragging-block {
        position: fixed;
        top: 0;
        left: 0;
        user-select: none;
        pointer-events: none;
      }

      .emma-journey-designer-main .emma-journey-designer-grid {
        .block {
          &.hover {
            cursor: pointer;

            .block-shape {
              stroke-width: 2px;
            }
          }

          &.unfinished {
            .block-shape {
              stroke-width: 2px;
            }
          }

          .block-trash,
          .cross-trash {
            cursor: pointer;
          }
        }
      }
    }

    .emma-journey-designer-sidebar {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      min-width: 350px;
      padding: 2rem 1rem;
      overflow-y: auto;

      @include shadow(get-shadow(light));

      scrollbar-color: #fff #c1c1c1;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 13px;
      }

      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px;
        border: solid 3px #fff;

        &:hover {
          background: #7d7d7d;
        }
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      .emma-journey-designer-sidebar-tab {
        display: block;
        padding-top: 0;
        padding-left: 0;
        position: absolute;
        top: max(calc(50vh - 120px), 80px);
        left: 0;
        z-index: 15;

        > .btn {
          width: 18px !important;
          height: 80px !important;
          position: fixed;
          z-index: 10;
        }
      }

      .emma-journey-designer-sidebar-contentainer {
        display: block;
        position: relative;
        z-index: 12;

        @include transition(array-get($m-config-header, header, self, transition, general));

        top: 0;

        @at-root .m-header--fixed.m-header--minimize-on & {
          @include transition(array-get($m-config-header, header, self, transition, hide));

          top: 0;
        }

        .emma-journey-audience-content {
          .titleOpt {
            font-weight: get-font-weight(bolder);
          }

          > div:first-child {
            border-right: 1px solid get-color($skin, panel);
          }
        }
      }
    }

    .emma-journey-designer-controls {
      .emma-journey-designer-controls--zoom {
        &:last-of-type {
          margin-left: 10px;
          margin-right: 10px;
        }

        > i {
          top: 52%;
          left: 49%;
        }
      }

      .emma-journey-designer-controls--restore-zoom {
        font-size: 0.9rem;
        transition: opacity 0.3s;
        padding: 6px 0;

        > a {
          cursor: pointer;
        }
      }
    }

    .emma-journey-designer-grid {
      overflow: auto;

      * {
        transition:
          width,
          height ease-in-out 200ms;
      }

      svg {
        overflow: visible;
      }

      .emma-journey-designer-droplet {
        overflow: visible;
        opacity: 0;

        &.hover,
        &.always-visible {
          opacity: 1;
        }

        .emma-journey-designer-droplet-label {
          overflow: visible;
          text-anchor: middle;

          tspan.label {
            font-weight: bold;
          }
        }
      }
    }
  }

  @include tablet-and-mobile {
    emma-journey-designer {
      .emma-journey-designer-sidebar {
        .emma-journey-designer-sidebar-contentainer {
          top: 0;
        }
      }

      .emma-journey-designer {
        .emma-journey-designer-left {
          .emma-journey-designer-left-container {
            top: 90px;
          }
        }
      }
    }
  }
}

@mixin build-journey--skin($skin) {
  emma-journey-designer {
    .emma-journey-designer {
      .emma-journey-designer-left {
        border-right: 1px solid get-color($skin, panel);
      }

      .emma-journey-designer-left .emma-journey-designer-palette .emma-journey-designer-section {
        &.section-type-source,
        &.section-type-end {
          color: get-brand-color();
        }

        &.section-type-action {
          color: get-state-color(emmablue, base);
        }

        &.section-type-flow {
          color: get-state-color(altpink, base);
        }
      }

      .dragging-block,
      .emma-journey-designer-left .emma-journey-designer-palette .emma-journey-designer-section,
      .emma-journey-designer-main .emma-journey-designer-grid {
        .block {
          .block-label {
            fill: get-state-color(emmadarkblue, base);
          }

          .block-stats {
            .block-stats--current {
              fill: get-state-color(emmadarkblue, base);
            }
            .block-stats--total {
              fill: get-color($skin, muted);
            }
          }

          .block-shape {
            stroke: rgba(0, 0, 0, 0);
          }

          &.block-category-action {
            .block-shape {
              fill: get-state-color(emmablue, base);
              stroke: rgba(get-state-color(emmablue, base), 0);
            }

            &.hover {
              .block-shape {
                stroke: rgba(get-state-color(emmablue, base), 0.5);
              }
            }
          }

          &.block-category-source,
          &.block-type-end {
            .block-shape {
              fill: get-brand-color();
              stroke: rgba(get-brand-color(), 0);
            }

            &.hover {
              .block-shape {
                stroke: rgba(get-brand-color(), 0.5);
              }
            }
          }

          &.block-category-flow {
            .block-shape {
              fill: get-state-color(altpink, base);
              stroke: rgba(get-state-color(altpink, base), 0);
            }

            &.hover {
              .block-shape {
                stroke: rgba(get-state-color(altpink, base), 0.5);
              }
            }
          }

          &.block-type-updateTag {
            .block-shape {
              fill: get-state-color(altviolet, base);
              stroke: rgba(get-state-color(altviolet, base), 0);
            }

            &.hover {
              .block-shape {
                stroke: rgba(get-state-color(altviolet, base), 0.5);
              }
            }
          }

          &.selected {
            .block-label {
              fill: get-state-color(emmadarkblue, base);
            }

            .block-shape {
              stroke: get-state-color(emmadarkblue, base);
            }
          }

          &.dragged {
            .block-shape {
              stroke: get-state-color(emmadarkblue, base);
            }
          }

          &.unfinished {
            .block-shape {
              fill: get-color($skin, panel, '+++');
            }

            &.selected {
              &.block-category-action {
                .block-shape {
                  stroke: get-state-color(emmablue, base);
                }
              }
              &.block-category-source,
              &.block-type-end {
                .block-shape {
                  stroke: get-brand-color();
                }
              }
              &.block-category-flow {
                .block-shape {
                  stroke: get-state-color(altpink, base);
                }
              }
              &.block-type-updateTag {
                .block-shape {
                  stroke: get-state-color(altviolet, base);
                }
              }
            }
          }
          .block-trash {
            fill: get-state-color(danger, base);
          }
        }
      }
    }

    .emma-journey-designer-sidebar {
      background-color: #fff;
    }

    .emma-journey-designer-grid {
      .emma-journey-designer-droplet {
        .emma-journey-designer-droplet-label {
          fill: get-color($skin, panel, '++++');
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component General

@include build-journey();

//== Component General Skin

@include build-journey--skin(light);
