//
//** Form Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--form-base() {
  .m-form {
    emma-form-block {
      display: block;

      .m-form__section {
        margin: 40px 0 40px 0;

        &:first-child,
        &:last-child {
          margin: 40px 0 40px 0;
        }
        &.m-form__section--first {
          margin-top: 0;
        }

        &.m-form__section--last {
          margin-bottom: 0;
        }
      }

      .emma-accordion {

        .m-form__section:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--form-skin() {
  $skin: get-default-skin();

  .m-form {
    .m-form__group {
      .form-control-label,
      .col-form-label,
      > label {
        color: get-color($skin, regular, '+');
        &.disabled {
          color: get-color($skin, muted);

          + .input-group {
            .input-group-label {
              color: get-color($skin, muted);
            }
          }
        }
      }

      .m-form__control-static {
        display: block;
        overflow-wrap: anywhere;
        color: get-color($skin, regular);
      }

      .required-label {
        color: get-color($skin, muted);
        font-style: italic;
        font-size: get-font-size(regular, '-');
        font-weight: get-font-weight(bold);
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &.m-form--group-separator {
      .m-form__group {
        border-bottom: 1px solid get-color($skin, panel, '-');

        &.m-form__group--last,
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.m-form--group-separator-dashed {
      .m-form__group {
        border-bottom: 1px dashed get-color($skin, panel);

        &.m-form__group--last,
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .m-form__help {
      color: get-color($skin, regular);
    }

    .m-form__separator {
      border-top: 1px solid get-color($skin, panel);

      &.m-form__separator--dashed {
        border-top: 1px dashed get-color($skin, panel);
      }
    }

    .input-group__footer {
      color: get-color($skin, muted);
      font-size: get-font-size(regular);
      font-weight: get-font-weight(bold);

      > p {
        margin-top: 5px;
      }
    }

    .m-form__heading {
      .m-form__heading-title {
        color: get-color($skin, regular);
      }

      .m-form__heading-sub {
        color: get-color($skin, regular, '-');
      }

      &.m-form__heading--bordered {
        border-bottom: 1px solid get-color($skin, panel);
      }

      &.m-form__heading--dashed {
        border-bottom: 1px dashed get-color($skin, panel);
      }

      .m-form__contextual {
        color: get-color($skin, muted);
        font-size: get-font-size(regular);
        font-weight: get-font-weight(bold);

        > p {
          margin-bottom: 5px;
        }

        > i {
          font-size: get-font-size(icon, '---');
        }
      }

      .m-form__url-preview {
        color: get-color($skin, regular);
        font-size: get-font-size(regular, '-');
        font-weight: get-font-weight(bold);
        margin-top: 15px;
        position: relative;
        word-break: break-word;

        > .m-btn--copy-url {
          position: absolute;
          top: calc(50% - 10px);
          left: -25px;
        }
      }

      .m-form__integration-logo {
        > img {
          max-width: 200px;
        }
      }
    }

    .m-form__section {
      color: get-color($skin, regular);
    }

    .m-form__actions {
      &.m-form__actions--line {
        border-top: 1px solid get-color($skin, panel);
      }

      &.m-form__actions--top {
        &.m-form__actions--line {
          border-top: 0;
          border-bottom: 1px solid get-color($skin, panel);
        }
      }

      &.m-form__actions--solid {
        background-color: get-color($skin, panel, '--');
        border-color: get-color($skin, panel, '--');
      }
    }

    &.m-form--fixed-width-sm {
      max-width: 540px;
    }

    &.m-form--fixed-width-md {
      max-width: 720px;
    }

    &.m-form--fixed-width-xl {
      max-width: 960px;
    }

    &.m-form--sidebar {
      display: flex;
      max-width: 1200px;

      form {
        flex: 1;
      }

      .m-form--sidebar-content {
        padding-left: 50px;
        width: 350px;

        .m-form--sidebar-content_sticky {
          position: -webkit-sticky;
          position: sticky;
          @include transition(array-get($m-config-header, header, self, transition, general));
          top: 210px; /* 120+70+20 */

          @at-root .m-header--fixed.m-header--minimize-on & {
            @include transition(array-get($m-config-header, header, self, transition, hide));
            top: 145px; /* 55+70+20 */
          }
        }

        .m-form__heading {
          .m-form--sidebar-row {
            display: flex;

            &.m-form--sidebar-row_last {
              border-bottom: 1px solid get-color($skin, panel);
            }
          }
        }
      }
    }

    emma-form-block {
      .m-form__section {
        .m-alert {
          font-size: get-font-size(regular);
        }

        .m-form__section--text,
        span,
        p,
        ul,
        ol {
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bold);
        } 
      }
    }
  }

  .m-form__heading {
    .m-form__heading-title {
      font-weight: get-font-weight(bolder);
      font-size: get-font-size(regular, '+++');
      color: get-color($skin, regular);
    }

    .m-form__heading-sub {
      color: get-color($skin, regular, '-');
    }

    &.m-form__heading--bordered {
      border-bottom: 1px solid get-color($skin, panel);
    }

    &.m-form__heading--dashed {
      border-bottom: 1px dashed get-color($skin, panel);
    }

    .m-form__contextual {
      color: get-color($skin, muted);
      font-size: get-font-size(regular);
      font-weight: get-font-weight(bold);

      > p {
        margin-bottom: 5px;
      }

      > i {
        font-size: get-font-size(icon, '---');
      }
    }

    .m-form__url-preview {
      color: get-color($skin, regular);
      font-size: get-font-size(regular, '-');
      font-weight: get-font-weight(bold);
      margin-top: 15px;
      position: relative;
      word-break: break-word;

      > .m-btn--copy-url {
        position: absolute;
        top: calc(50% - 10px);
        left: -25px;
      }
    }

    .m-form__integration-logo {
      > img {
        max-width: 200px;
      }
    }
  }

  .m-form__contextual {
    color: get-color($skin, muted);
    font-size: get-font-size(regular);
    font-weight: get-font-weight(bold);

    > p {
      margin-bottom: 5px;
    }

    > i {
      font-size: get-font-size(icon, '---');
    }
  }

  @include tablet-and-mobile {
    .m-form {
      &.m-form--sidebar {
        flex-direction: column;

        .m-form--sidebar-content {
          padding-left: 0;
          width: auto;
          order: 1;

          .m-form--sidebar-content_sticky {
            position: relative;
            top: auto;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px dashed get-color($skin, panel);
          }
        }
      }

      form {
        order: 2;
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--form-base();

//== Component Skin
@include m-build-component--form-skin();
