//
//** Custom Dashboards
//

//== General

@mixin build-newComponentPlaceholder() {
  .newComponentPlaceholder {
    display: table;
    width: 100%;
    height: 100%;

    .newComponentPlaceholder__content {
      display: table-cell;
      vertical-align: middle;

      .newComponentPlaceholder__container--svg,
      .newComponentPlaceholder__container--btn {
        text-align: center;

        > img.svgImage {
          max-width: 100px;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}

// Drag&Drop styles (gu-mirror)

.gu-mirror,
.emma-dashboard {
  @include build-newComponentPlaceholder();
}

.emma-modal {
  @include build-newComponentPlaceholder();

  .newComponentPlaceholder {
    padding: 40px;

    .newComponentPlaceholder__content {
      .newComponentPlaceholder__container--svg {
        > img.svgImage {
          max-width: 200px;
        }
      }
    }
  }
}
