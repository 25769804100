//
//** Dropdown Customization
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--dropdown-menu-base() {
  // dropdown menu

  .dropdown-menu {
    border: 0;
    margin: 0;
    z-index: 11;

    @include border-radius(0);

    @include rounded {
      @include border-radius(array-get($m--border-radius, dropdown));
    }

    &::before,
    &::after {
      display: none !important;
    }

    > li > a,
    > .dropdown-item {
      display: block;
      padding: 0.7rem 2rem 0.7rem 1.2rem;
      cursor: pointer;

      @include icons-size(array-get($m--bootstrap-extend-config, dropdown, icon, sizes));

      i {
        display: inline-block;
        line-height: 0;
        vertical-align: middle;
        margin-right: 0.4rem;
        position: relative;
        top: -0.08rem;
      }

      &.active {
        text-decoration: none;
        position: relative;

        &::after {
          position: absolute;
          right: 7px;
          top: calc(50% - 13px);
          font-family: 'Line Awesome Free';
          content: '\f00c';
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-size: 1.2rem;
          font-weight: 900;
        }
      }

      &.groupby {
        user-select: none;
        padding: 8px 10px;
        cursor: default;
      }
    }

    > .dropdown-menu--grid-tiles {
      display: flex;

      .dropdown-menu--tile {
        flex: 1;
        overflow-x: hidden;

        > li > a,
        > .dropdown-item {
          display: block;
          padding: 0.7rem 2rem 0.7rem 1.2rem;
          cursor: pointer;

          @include icons-size(array-get($m--bootstrap-extend-config, dropdown, icon, sizes));

          i {
            display: inline-block;
            line-height: 0;
            vertical-align: middle;
            margin-right: 0.4rem;
            position: relative;
            top: -0.08rem;
          }

          &.active {
            text-decoration: none;
            position: relative;

            &::after {
              position: absolute;
              right: 7px;
              top: calc(50% - 13px);
              font-family: 'Line Awesome Free';
              content: '\f00c';
              text-decoration: inherit;
              text-rendering: optimizeLegibility;
              text-transform: none;
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              font-size: 1.2rem;
              font-weight: 900;
            }
          }

          &.groupby {
            user-select: none;
            padding: 8px 10px;
            cursor: default;
          }
        }

        &.emma-date-picker--calendar {
          flex: none;
        }
      }
    }
  }

  .emma-dropdown .dropdown-menu--tile {
    max-height: 13rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  //== Dropdown toggle

  .nav-link.dropdown-toggle,
  .btn.dropdown-toggle {
    &::after {
      text-align: center;
      display: inline;
      border: 0;
      font-size: 0.8rem;
      line-height: 0;
      vertical-align: middle;
      top: -0.1em;
      position: relative;

      @include m-generate-arrow-emma-icon(down, true);
    }

    &.dropdown-toggle-split {
      &::after {
        right: 1px;
      }
    }
  }

  //== Dropright

  .btn-group.dropright {
    .nav-link.dropdown-toggle,
    .btn.dropdown-toggle {
      &::before {
        display: none;
      }

      &::after {
        @include m-generate-arrow-emma-icon(right, true);
      }
    }
  }

  //== Dropleft

  .btn-group.dropleft {
    .nav-link.dropdown-toggle,
    .btn.dropdown-toggle {
      &::before {
        display: none;
      }

      &::after {
        @include m-generate-arrow-emma-icon(left, true);
      }
    }
  }

  //== Dropdown element

  .dropdown {
    // inline dropdown

    &.m-dropdown--inline {
      display: inline-block;
    }

    // dropdown menu button arrow

    .m-dropdown__arrow {
      font-size: 0.8rem !important;
      padding-left: 0.8rem;
    }
  }
}

//== Component Skin
@mixin m-build-component--dropdown-menu-skin() {
  $skin: get-default-skin();

  // dropdown menu

  .dropdown-menu {
    border: 0;

    @include shadow(get-shadow($skin));

    > li > a,
    > .dropdown-item {
      color: get-color($skin, regular);

      i {
        color: get-color($skin, icon);
      }

      &:hover {
        background: get-color($skin, panel, '-');
        text-decoration: none;
        color: get-color($skin, link);

        i {
          color: get-color($skin, link);
        }

        &.active {
          background: get-color($skin, panel);
          color: get-state-color(emmadarkblue, base);

          i {
            color: get-state-color(emmadarkblue, base);
          }

          &::after {
            color: get-color($skin, link);
          }
        }
      }

      &.active {
        background: get-color($skin, panel);
        color: get-state-color(emmadarkblue, base);

        i {
          color: get-state-color(emmadarkblue, base);
        }

        &::after {
          color: get-color($skin, link);
        }
      }

      &.groupby {
        color: $dropdown-link-disabled-color;
      }
    }

    > .dropdown-menu--grid-tiles {
      .dropdown-menu--tile {
        > li > a,
        > .dropdown-item {
          color: get-color($skin, regular);

          i {
            color: get-color($skin, icon);
          }

          &:hover {
            background: get-color($skin, panel, '-');
            text-decoration: none;
            color: get-color($skin, link);

            i {
              color: get-color($skin, link);
            }
          }

          &.active {
            background: get-color($skin, panel);
            color: get-state-color(emmadarkblue, base);

            i {
              color: get-state-color(emmadarkblue, base);
            }

            &::after {
              color: get-color($skin, link);
            }
          }

          &.groupby {
            color: $dropdown-link-disabled-color;
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--dropdown-menu-base();

//== Component Skin
@include m-build-component--dropdown-menu-skin();
