//
//** EMMA Date Picker
//

@mixin m-build-component--emmadatepicker-base() {

  emma-date-picker {

    .emma-date-picker--button {

      > i {
        padding-bottom: 3px;
      }

      >span.emma-date-picker--button--prefix,
      >span.emma-date-picker--button--label,
      >span.emma-date-picker--button--placeholder {
        padding-left: 0.5rem;
      }
    }

    ngb-datepicker {
      border: none;

      .ngb-dp-header {
        background-color: transparent !important;
        padding-top: .5rem;
        padding-bottom: .5rem;

        .ngb-dp-navigation-chevron {
          border-style: solid;
          border-width: 2px 2px 0 0;
          display: inline-block;
          width: .65rem;
          height: .65rem;
          margin-left: .25rem;
          margin-right: .15rem;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }

        .ngb-dp-arrow-next .ngb-dp-navigation-chevron {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        .ngb-dp-month-name {
          font-size: 1rem;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .ngb-dp-months {

        .ngb-dp-month {

          .ngb-dp-weekdays {
            background-color: transparent !important;
            border-bottom: none;

            .ngb-dp-weekday {
              text-transform: lowercase;
              font-style: normal;
              font-size: 1rem;
              font-weight: 500;
              width: 2.5rem;
              height: 2.5rem;

              &:first-letter {
                text-transform: uppercase;
              }
            }
          }

          .ngb-dp-day {
            width: auto;
            height: auto;

            .emma-date-picker--day {
              font-size: 1rem;
              font-weight: 400;
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 3px;
              padding-top: 7px;
              text-align: center;

              &.rangeIn {
                border-radius: 0;
              }

              &.rangeFrom {
                border-radius: 3px 0 0 3px;
              }

              &.rangeTo {
                border-radius: 0 3px 3px 0;
              }

              &.rangeFrom.rangeTo {
                border-radius: 3px;
              }
            }

            &.hidden {
              width: 2.5rem;
              height: 2.5rem;
            }

            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .emma-date-picker--drop {

      .emma-date-picker--menu {
        z-index: 11;
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--emmadatepicker-skin($skin) {

  emma-date-picker {

    ngb-datepicker {

      .ngb-dp-header {

        .ngb-dp-navigation-chevron {
          color: get-color($skin, regular);
        }
      }

      .ngb-dp-months {

        .ngb-dp-month {

          .ngb-dp-weekdays {

            .ngb-dp-weekday {
              color: get-color($skin, regular);
            }
          }

          .ngb-dp-day {

            .emma-date-picker--day {

              &:hover {
                background: get-color($skin, panel);
              }

              &.rangeIn {
                background: darken(get-color($skin, panel), 10%);
                color: get-color($skin, regular);
              }

              &.selected,
              .rangeTo {
                background: get-state-color(brand, base);
                color: get-state-color(brand, inverse);
              }

              &.rangeIn,
              &.rangeFrom,
              &.rangeTo,
              &.selected,
              &.focused {

                &:hover {
                  background: get-state-color(brand, base);
                  color: get-state-color(brand, inverse);
                }
              }
            }

            &.disabled {

              .emma-date-picker--day {

                &:hover {
                  background: get-state-color(brand, inverse);
                }
              }
            }
          }
        }
      }
    }

    &.ng-dirty.ng-invalid,
    &.ng-touched.ng-invalid {

      .emma-date-picker {

        .emma-date-picker--drop.dropdown {

          .dropdown-toggle.emma-date-picker--button {

            border-color: array-get($m--forms, input, skins, $skin, ko, border, default);
            color: array-get($m--forms, input, skins, $skin, ko, bg, default);

          }
        }
      }
    }
  }
}

@include m-build-component--emmadatepicker-base();
@include m-build-component--emmadatepicker-skin(light);
