//
//** EMMA Date Range
//

emma-date-range-group-picker.topbar-location {
  display: table-cell;
  vertical-align: top;
  height: auto;
  width: 100%;
}

.topbar-location .m-subheader {
  padding: 0;
  height: 100%;

  &.d-flex {
    justify-content: flex-end;
  }

  //== Page Date Range Picker

  emma-date-picker,
  emma-time-group-picker {

    .emma-date-picker--drop,
    .emma-time-group-picker--drop {

      .btn.m-btn--hover-emmagreen {

        .emma-date-picker--button--prefix,
        .emma-time-group-picker--button--prefix {
          display: inline-block;
          font-weight: get-font-weight(bold);
          padding-right: 10px;
          transition: color .15s ease-in-out;
        }

        .emma-date-picker--button--label,
        .emma-time-group-picker--button--label {
          display: inline-block;
          font-weight: get-font-weight(bolder);
          color: get-brand-color() !important;
          transition: color .15s ease-in-out;
        }

        > i {
          color: #fff !important;
          transition: color .15s ease-in-out;
        }

        &:hover {

          .emma-date-picker--button--label,
          .emma-time-group-picker--button--label {
            color: #fff !important;
          }
        }
      }

      &.show {

        .btn.m-btn--hover-emmagreen {

          .emma-date-picker--button--label,
          .emma-time-group-picker--button--label {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.m-body {

  emma-date-picker,
  emma-time-group-picker {

    .emma-date-picker--drop.emma-date-picker--drop-range {

      .emma-date-picker--menu {
        min-width: 400px;
        max-width: 525px;

        .dropdown-menu--grid-tiles {
          flex-wrap: wrap;
          justify-content: center;

          .dropdown-menu--tile {

            &.emma-date-picker--predefined {
              display: flex;
              flex-wrap: wrap;
              overflow-x: visible;

              .dropdown-item.emma-date-picker--predefined-item {
                flex: 1 1 30%;
                text-align: center;

                &:last-of-type {
                  flex: 1 1 100%;
                }
              }
            }

            &.emma-date-picker--calendar {
              flex: unset;
              overflow-x: visible;

              .ngb-dp-month:first-child .ngb-dp-week {
                padding-left: .5rem;
              }

              .ngb-dp-month:last-child .ngb-dp-week {
                padding-right: .5rem;
              }
            }
          }
        }
      }
    }
  }
}

@include tablet-and-mobile {

  .topbar-location .m-subheader {
    &.d-flex {
      justify-content: flex-start;
    }

    emma-date-picker,
    emma-time-group-picker {

      .emma-date-picker--drop,
      .emma-time-group-picker--drop {

        .btn.m-btn--hover-emmagreen {
          color: get-color(light, regular) !important;

          > i {
            color: get-color(light, regular) !important;
          }

          &:hover {

            .emma-date-picker--button--prefix,
            .emma-time-group-picker--button--prefix {
              color: #fff !important;
            }

            .emma-date-picker--button--label,
            .emma-time-group-picker--button--label {
              color: #fff !important;
            }

            > i {
              color: #fff !important;
            }
          }
        }

        &.show {

          .btn.m-btn--hover-emmagreen {

            .emma-date-picker--button--prefix,
            .emma-time-group-picker--button--prefix {
              color: #fff !important;
            }

            .emma-date-picker--button--label,
            .emma-time-group-picker--button--label {
              color: #fff !important;
            }

            > i {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
