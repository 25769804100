//
//** User Welcome
//

//== General

@mixin build-explore() {

  .explorePortlet {

    .col-lg-8 {
      padding-right: array-get($emma--widget, layout, self, space) !important;
    }

    .col-lg-4 {
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }

    .addSegment {
      display: flex;
      align-items: center;

      >*:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .chartExplorePortlet {

    .col-lg-4 {
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }

    .tableSegment {
      padding-right: array-get($emma--widget, layout, self, space) !important;
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }
  }

  @include tablet-and-mobile {

    .explorePortlet {

      .col-lg-8 {
        padding-right: 0 !important;
      }
    }

    .chartExplorePortlet {

      .col-lg-4 {
        padding-left: 0 !important;
      }

      .tableSegment {
        padding-right: array-get($emma--widget, layout, self, space) !important;
        padding-left: array-get($emma--widget, layout, self, space) !important;
      }
    }
  }
}

//==== Generate Component ====//

//== Component General

@include build-explore();
