//
//** Section Component
//

//== Component Base
@mixin m-build-component--typography-part-base() {
  //== Page Title
  .m-part {
    margin-bottom: 0;

    .m-part__icon {
      padding-right: 20px;

      > i {
        font-size: 2rem;
        vertical-align: middle;
      }
    }

    .m-part__title {
      display: inline-block;
      padding: 7px 0 7px 25px;
      font-family: Roboto, sans-serif;
      font-size: 1.75rem;
      font-weight: get-font-weight(bold);
      vertical-align: middle;
      margin: 0 15px 0 0;

      > i {
        font-size: 1.75rem;
      }
    }
  }

  /*.m-part {
        margin: 0 0 40px 0;

    	.m-part__heading {
            display: block;
            margin: 0 0 20px 0;
            padding: 0;
            font-family: get-heading-font-family();
            font-weight: get-font-weight(bolder);
        }

        h4.m-part__heading {
            font-size: get-font-size(regular, '+');
        }

        h3.m-part__heading {
            font-size: get-font-size(regular, '++');
        }

        h2.m-part__heading {
            font-size: get-font-size(regular, '+++');
        }

        h1.m-part__heading {
            font-size: get-font-size(regular, '++++');
        }

        .m-part__sub {
            display: block;
            margin: 5px 0 10px 0;
            font-size: get-font-size(regular);
        }

        .m-part__content {
            margin: 0 0 10px 0;
            font-size: get-font-size(regular);
        }

        .m-part__separator {
            margin: 5px 0;
            height: 1px;
            overflow: hidden;
            display: block;
        }

        &.m-part--last {
            margin-bottom: 0;

            .m-part__content {
                margin-bottom: 0;
            }
        }
    }*/
}

//== Component Skin
@mixin m-build-component--typography-part-skin($skin) {
  @include component-skin(m-part, $skin) {
    .m-part__icon {
      color: get-color($skin, panel, '++++');
    }

    .m-part__title {
      color: get-color($skin, regular, '+');

      &.m-part__title--separator {
        border-left: 1px solid get-color($skin, panel, '+');
      }
    }

    /*.m-part__heading {
            color: get-color($skin, regular, '+');
        }

        .m-part__sub {
            color: get-color($skin, regular, '-');
        }

        .m-part__content {
            color: get-color($skin, regular);
        }*/
  }
}

//== Build Component Base
@include m-build-component--typography-part-base();

//== Build Component - Light Skin
@include m-build-component--typography-part-skin(light);

//== Build Component - Dark Skin
@include m-build-component--typography-part-skin(dark);
