//
//** EMMA Date Picker
//

@mixin m-build-component--emmatimepicker-base() {

  emma-time-picker {

    .timePicker-container {
      display: flex;

      .timePicker-container-section {
        display: flex;
        align-items: center;

        &:not( .timePicker-container-section--showArrow ) {

          &:first-of-type {

            .ng-select-container {
              padding-left: 1.5rem;
            }
          }
        }

        &--separator {
          display: flex;
          flex: 0;
          align-self: stretch;
          align-items: center;
          padding: 0 5px;
        }

        &--noRadius-right {

          .ng-select-container {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            padding-right: .2rem;
          }
        }

        &--noRadius-left {

          .ng-select-container {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }

        emma-select {

          .ng-arrow-wrapper {
            opacity: 0;
            transition: all .3s ease-in-out;
          }

          &:hover {

            .ng-arrow-wrapper {
              opacity: 1;
            }
          }
        }

        &--showArrow {

          emma-select {

            .ng-arrow-wrapper {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--emmatimepicker-skin($skin) {

  emma-time-picker {

    .timePicker-container {

      .timePicker-container-section {

        &--separator {
          background-color: get-state-color(metal, base);
        }
      }
    }

    &.ng-dirty.ng-invalid,
    &.ng-touched.ng-invalid {

      .timePicker-container {

        .timePicker-container-section {

          .ng-select {

            .ng-select-container {
                border-color: array-get($m--forms, input, skins, $skin, ko, border, default);
                color: array-get($m--forms, input, skins, $skin, ko, bg, default);
            }
          }
        }
      }
    }
  }
}

@include m-build-component--emmatimepicker-base();
@include m-build-component--emmatimepicker-skin(light);
