//
//** EMMA ngx-toastr Component
//

//== Component Base

@mixin m-build-component--emma-toast-notification-base() {

  .toast-container {
    top: 20px;
    right: 20px;

    .ngx-toastr {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      transition: all .15s ease-in-out;
      opacity: .85 !important;
      padding: 0.75rem 1.25rem 0.75rem 1rem;
      background-image: none;

      @include shadow(get-shadow(light));

      &::after {
        font-family: 'Line Awesome Free';
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-size: 26px;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        padding-right: 1rem;
      }

      &:hover,
      &:focus {
        // transform: scale(1.03);
        opacity: 1 !important;
        @include shadow(get-shadow(light));
      }

      > div {
        line-height: 1.2;
        flex: 1 1 auto;
      }

      .toast-close-button {
        align-self: flex-start;
        right: -0.75rem;
        top: -0.75rem;
        font-weight: 300;
        font-size: 24px;
        float: none;
        line-height: 1;
        text-shadow: none;
        opacity: 1;

        &:hover,
        &:focus {
          opacity: .5;
        }
      }

      &.toast-info {
        color: get-color(dark, regular, '-') !important;
        background-color: get-color(dark, box, '---');
        border-color: get-color(dark, box, '---');

        &::after {
          content: "\f05a";
        }

        .toast-close-button {
          &:hover,
          &:focus {
            color: get-color(dark, regular, '-');
          }
        }
      }

      &.toast-success {
        color: get-state-color(altgreen, inverse) !important;
        background-color: get-state-color(altgreen, base);
        border-color: get-state-color(altgreen, base);

        &::after {
          content: "\f058";
        }

        .toast-close-button {
          &:hover,
          &:focus {
            color: get-state-color(altgreen, inverse);
          }
        }
      }

      &.toast-warning {
        color: get-state-color(altorange, inverse) !important;
        background-color: get-state-color(altorange, base);
        border-color: get-state-color(altorange, base);

        &::after {
          content: "\f06a";
        }

        .toast-close-button {
          &:hover,
          &:focus {
            color: get-state-color(altorange, inverse);
          }
        }
      }

      &.toast-error {
        color: get-state-color(danger, inverse) !important;
        background-color: get-state-color(danger, base);
        border-color: get-state-color(danger, base);

        &::after {
          content: "\f057";
        }

        .toast-close-button {
          &:hover,
          &:focus {
            color: get-state-color(danger, inverse);
          }
        }
      }
    }
  }

  .m-header--fixed.m-header--minimize-on {
    .toast-container {

      @include transition(
        array-get($m-config-header, header, self, transition, hide)
      );
    }
  }

  .m-header--fixed.m-header--minimize-off {
    .toast-container {

      @include transition(
        array-get($m-config-header, header, self, transition, general)
      );
    }
  }

  .m-header--fixed {
    &.m-header--minimize-on:has(app-header-nav) {
      .toast-container {
        top: calc(55px + 20px);
      }
    }

    &.m-header--minimize-off:has(app-header-nav) {
      .toast-container {
        top: calc(55px + 65px + 20px);
      }
    }
  }

  .m-header--fixed:has(app-header-nav) {
    .toast-container {
      top: calc(55px + 65px + 20px);
    }
  }

  .m-header--fixed:has(app-header-nav):has(emma-filter-bar) {
    .toast-container {
      top: calc(55px + 65px + 58px + 20px);
    }
  }

  .m-header--fixed:has(emma-filter-bar) {
    &.m-header--minimize-on:has(app-header-nav) {
      .toast-container {
        top: calc(55px + 58px + 20px);
      }
    }

    &.m-header--minimize-off:has(app-header-nav) {
      .toast-container {
        top: calc(55px + 65px + 58px + 20px);
      }
    }
  }
}


//==== Generate Component ====//

//== Component Base
@include m-build-component--emma-toast-notification-base();