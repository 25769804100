//
//** User Welcome
//

//== General

@mixin build-user-reports() {
  .emmaUserReports {
    display: inline-block;
    position: relative;

    .emmaUserReports__popup {
      position: absolute;
      min-width: 250px;
      padding: 10px 10px 0;
      z-index: 12;
      left: 0;
      top: 100%;

      @include border-radius(
        array-get($m-config-menu-header, desktop, build, layout, item, submenu, self, border-radius)
      );

      .emmaUserReports__available {
        max-height: 300px;
        overflow-y: auto;
        margin-left: -10px;
        margin-right: -10px;
        background:
                /* Shadow covers */
          linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
          linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
          /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
          radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
        background-repeat: no-repeat;
        background-color: #fff;
        background-size:
          100% 30px,
          100% 30px,
          100% 10px,
          100% 10px;
        background-attachment: local, local, scroll, scroll;

        .emmaUserReports__unit {
          display: flex;
          align-items: center;
          max-width: 300px;

          .emmaUserReports__name {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            cursor: pointer;
            padding: 0.7rem 1.2rem;
          }

          .emmaUserReports__delete {
            margin-left: auto;
            margin-right: 5px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .explorePortlet {
    .col-lg-8 {
      padding-right: array-get($emma--widget, layout, self, space) !important;
    }

    .col-lg-4 {
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }

    .addSegment {
      display: flex;
      align-items: center;

      > *:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .chartExplorePortlet {
    .col-lg-4 {
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }

    .tableSegment {
      padding-right: array-get($emma--widget, layout, self, space) !important;
      padding-left: array-get($emma--widget, layout, self, space) !important;
    }
  }
}

@mixin build-user-reports-skin($skin) {
  .emmaUserReports {
    .emmaUserReports__popup {
      background-color: array-get(
        $m-config-menu-header,
        desktop,
        build,
        skins,
        $skin,
        item,
        submenu,
        self,
        bg-color
      );

      @include shadow(
        array-get($m-config-menu-header, desktop, build, skins, $skin, item, submenu, self, box-shadow)
      );

      .emmaUserReports__available {
        .emmaUserReports__unit {
          &:hover {
            background: get-color($skin, panel, '-');
          }

          .emmaUserReports__name {
            &:hover {
              background: get-color($skin, panel, '-');
              color: get-color($skin, link);
            }
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component General

@include build-user-reports();

@include build-user-reports-skin(light);
