.fullheight {
  min-height: 100vh;
}

//== Layout Shared Settings
$m-aside-left-width: 255px;
$m-aside-left-width-minimize: 80px;
$m-aside-left-offcanvas-width: 255px;

//== Core icon codes new version (lineawesome icons: https://icons8.com/line-awesome)
$m--emma-icon-codes: (
  close: '\f00d',
  down: '\f107',
  up: '\f106',
  left: '\f104',
  right: '\f105',
  plus: '\f067',
  minus: '\f068',
  tick: '\f00c',
  doubletick: '\f560',
);

/**
 * Custom Icons for LA
 */

.la.la-emma {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/IsotipoEMMA-Darkblue.svg');
    min-height: 10px;
    width: 17px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-s3 {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/logoIconS3.svg');
    min-height: 15px;
    width: 17px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-font-smoothing: antialiased;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-google-ads {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/logoiconGoogleAds.svg');
    min-height: 12px;
    width: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 3px;
    -webkit-font-smoothing: antialiased;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-petal-ads {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/logoiconPetalAds.svg');
    min-height: 12px;
    width: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 3px;
    -webkit-font-smoothing: antialiased;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-apple-skad {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/logoIconAppleSKAd.svg');
    min-height: 13px;
    width: 11px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 3px;
    -webkit-font-smoothing: antialiased;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-tiktok {
  &::before {
    content: '';
    background-image: url('../../assets/media/emma/img/icon/logoIconTikTok.svg');
    min-height: 13px;
    width: 11px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 3px;
    -webkit-font-smoothing: antialiased;
  }

  &.whiteIcon {
    filter: brightness(100);
  }
}

.la.la-assisted-installs {
  &::before {
    content: '';
    -webkit-mask: url('../../assets/media/emma/img/icon/assistedInstallsIcon.svg') no-repeat center;
    mask: url('../../assets/media/emma/img/icon/assistedInstallsIcon.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    min-height: 1em;
    width: 1em;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 2px;
    -webkit-font-smoothing: antialiased;
    background-color: currentColor;
  }
}

.la.la-translate {
  &::before {
    content: '';
    -webkit-mask: url('../../assets/media/emma/img/icon/iconTranslate.svg') no-repeat center;
    mask: url('../../assets/media/emma/img/icon/iconTranslate.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    min-height: 1em;
    width: 1em;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 2px;
    -webkit-font-smoothing: antialiased;
    background-color: currentColor;
  }
}

.la.la-circle-full {
  &::before {
    content: '';
    -webkit-mask: url('../../assets/media/emma/img/icon/circleFullIcon.svg') no-repeat center;
    mask: url('../../assets/media/emma/img/icon/circleFullIcon.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    min-height: 1em;
    width: 1em;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 2px;
    -webkit-font-smoothing: antialiased;
    background-color: currentColor;
  }
}

.lab.la-facebook {
  font-size: 1.25rem;
}

.la.la-apple {
  font-size: 1.1rem;
}

/**
 * Comienzo de Animación de Shake del Isotipo de EMMA
 */

.customEMMALoading {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100;
}

.customEMMALoading-Bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(247, 247, 247, 0.75);
}

.m-page--loading-opaque {
  .customEMMALoading-Bg {
    background-color: rgba(247, 247, 247, 1) !important;
  }
}

.customEMMALoading-Iso {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 100px;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 150;
}

@-webkit-keyframes shake-emma {
  0%,
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes shake-emma {
  0%,
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.loadingIsoEMMA {
  -webkit-animation: shake-emma 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
  -moz-animation: shake-emma 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
  animation: shake-emma 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
}

/**
* Fin de Animación de Shake del Isotipo de EMMA
*/
