//
//** Subheader
//

@mixin m-build-subheader-base() {
  $skin: light;

  //== General Mode
  .m-subheader {
    padding: 40px 0 0 0;

    &.m-subheader-filters {
      padding: 15px 30px 0;
      background: get-color(light, panel);
      position: fixed;
      z-index: 12;
      left: 0;
      right: 0;
      @include shadow(get-shadow(dark, '++'));
      @include fix-fixed-position-lags();
      // margin-left: -#{array-get($m-config-base, page, container, padding, wide, x, desktop)};
      // margin-right: -#{array-get($m-config-base, page, container, padding, wide, x, desktop)};

      > .filtersContainer {
        padding-top: 0;

        .dropdown {
          margin-bottom: 15px;

          > .btn {
            background-color: transparent;
            border-color: transparent;
            color: get-color($skin, regular);

            &:hover {
              background-color: get-brand-color();
              border-color: get-brand-color();
              color: get-brand-inverse-color();
            }
          }
        }
      }

      .m-header--fixed.m-header--minimize-off & {
        @include transition(array-get($m-config-header, header, self, transition, general));
        @include transform(translateY(0));
      }
      .m-header--fixed.m-header--minimize-on & {
        @include transition(array-get($m-config-header, header, self, transition, hide));
        @include transform(
          translateY(-#{array-get($m-config-header, header, self, height, desktop, top, default)})
        );
      }
    }

    //== Page Title
    .m-subheader__title {
      display: inline-block;
      padding: 7px 25px 7px 0;
      font-size: 1.75rem;
      font-weight: get-font-weight(boldest);
      vertical-align: middle;
      margin: 0 15px 0 0;
      color: get-color($skin, regular, '+');

      &.m-subheader__title--separator {
        border-right: 1px solid get-color($skin, panel, '+');
      }

      > i {
        font-size: 1.75rem;
      }
    }

    .m-subheader__description {
      font-size: 1.1rem;
      color: get-color($skin, regular, '----');
    }

    //== Page Breadcrumbs
    .m-subheader__breadcrumbs.m-nav {
      > .m-nav__item {
        padding: 0;

        > .m-nav__link {
          > .m-nav__link-text {
            color: get-color($skin, regular, '---');
          }

          > .m-nav__link-icon {
            font-size: get-font-size(icon);
            color: get-color($skin, regular, '---');
          }

          &:hover {
            > .m-nav__link-text {
              color: get-brand-color();
            }

            > .m-nav__link-icon {
              color: get-brand-color();
            }
          }
        }

        &.m-nav__item--home {
          > .m-nav__link {
            > .m-nav__link-icon {
              padding-right: 0;
            }
          }
        }
      }

      > .m-nav__separator {
        padding: 0 3px;
        color: get-color($skin, regular, '---');
      }
    }
  }

  //== Mobile Mode
  @include tablet-and-mobile {
    .m-subheader {
      padding: 30px 0 0 0;

      &.m-subheader-filters {
        // margin-left: -#{array-get($m-config-base, page, container, padding, wide, x, mobile)};
        // margin-right: -#{array-get($m-config-base, page, container, padding, wide, x, mobile)};
      }

      //== Page Date Range Picker
      .emma-date-picker {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }

  //== Mobile Mode
  @include mobile {
    .m-subheader {
      padding: 20px 0 0 0;

      //== Page Title
      .m-subheader__title {
        display: block;
        margin: 0 0 0 0;
        padding: 0;

        &.m-subheader__title--separator {
          border-right: 0;
        }
      }

      //== Page Breadcrumbs
      .m-subheader__breadcrumbs.m-nav {
        margin-top: 0.5rem;

        > .m-nav__separator {
          padding: 0 3px;
        }
      }

      //== Page Date Range Picker
      .emma-date-picker {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}

//== Build Brand Base
@include m-build-subheader-base();
