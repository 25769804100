//
//** EMMA Switch Component
//

//== Component Base
@mixin m-build-component--emma-slider-base() {
  .emma-timerange.disabled,
  .emma-timeago.disabled {
    .input-group-slider-sibling {
      opacity: 0.65;
    }
  }

  emma-slider {
    display: block;
    padding-top: 20px;
  } 

  @each $name, $color in $m--state-colors {
    .emma-ngx-slider-#{$name} {
      &.ngx-slider {
        margin: 0;

        &.with-legend {
          margin-bottom: 0;
        }

        .ngx-slider-bar-wrapper {
          padding-top: 14px;
        }

        .ngx-slider-bar,
        .ngx-slider-selection {
          height: 8px;
          border-radius: 8px;
        }

        .ngx-slider-pointer {
          box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
          height: 24px;
          width: 24px;
          top: -10px;
          outline: none;

          &::after {
            top: 8px;
            left: 8px;
          }
        }

        .ngx-slider-tick {
          width: 1px;

          .ngx-slider-tick-legend {
            opacity: 1;
            font-size: 1rem;
          }

          &.ngx-slider-selected {
            .ngx-slider-tick-legend {
              opacity: 1;
            }
          }
        }

        .ngx-slider-bubble {
          font-size: 1rem;
          bottom: 10px;
        }

        &.animate {
          .ngx-slider-bar-wrapper {
            -webkit-transition: 0.15s ease-in-out all;
            transition: 0.15s ease-in-out all;
          }

          .ngx-slider-bar,
          .ngx-slider-selection {
            -webkit-transition: 0.15s ease-in-out all;
            transition: 0.15s ease-in-out all;
          }

          .ngx-slider-pointer {
            -webkit-transition: 0.15s ease-in-out all;
            transition: 0.15s ease-in-out all;

            &::after {
              -webkit-transition: 0.15s ease-in-out all;
              transition: 0.15s ease-in-out all;
            }
          }

          .ngx-slider-tick {
            -webkit-transition: 0.15s ease-in-out all;
            transition: 0.15s ease-in-out all;

            .ngx-slider-tick-legend {
              -webkit-transition: 0.15s ease-in-out all;
              transition: 0.15s ease-in-out all;
            }
          }

          .ngx-slider-bubble {
            -webkit-transition: 0.15s ease-in-out all;
            transition: 0.15s ease-in-out all;
          }
        }

        &.ng-dirty.ng-invalid::before,
        &.ng-touched.ng-invalid::before {
          content: '';
          position: absolute;
          left: -15px;
          top: -3px;
          height: 10px;
          width: 10px;
          border-radius: 50px;
        }
      }
    }

    emma-slider,
    emma-multi-slider,
    emma-timeduration {
      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {
        .ngx-slider::before {
          content: '';
          position: absolute;
          left: -15px;
          top: -3px;
          height: 10px;
          width: 10px;
          border-radius: 50px;
        }
      }
    }
  }
}

@mixin m-build-component--emma-slider-skin($config) {
  $skin: get-default-skin();

  @each $name, $color in $m--state-colors {
    .emma-ngx-slider-#{$name} {
      &.ngx-slider {
        .ngx-slider-bar {
          background-color: array-get($config, input, skins, $skin, solid, bg, default);
        }

        .ngx-slider-selection {
          background-color: array-get($color, base);
        }

        .ngx-slider-pointer {
          background-color: #fff;

          &::after {
            background-color: array-get($config, input, skins, $skin, solid, bg, default);
          }

          &:hover {
            &::after {
              background-color: array-get($config, input, skins, $skin, solid, bg, focus);
            }
          }

          &.ngx-slider-active {
            &::after {
              background-color: array-get($color, base);
            }
          }
        }

        .ngx-slider-tick {
          background-color: array-get($config, input, skins, $skin, solid, bg, focus);
        }

        .ngx-slider-tick.ngx-slider-selected {
          background-color: rgba(array-get($color, inverse), 0.5);
          color: array-get($color, base);
        }

        .ngx-slider-bubble {
          color: get-color($skin, regular);

          &.ngx-slider-limit {
            color: get-color($skin, regular);
          }
        }

        &[disabled] {
          opacity: 0.65;

          .ngx-slider-selection {
            background-color: array-get($config, input, skins, $skin, solid, bg, focus);
          }

          .ngx-slider-pointer {
            background-color: array-get($config, input, skins, $skin, solid, bg, default);

            &::after {
              background-color: array-get($config, input, skins, $skin, solid, bg, default);
            }

            &:hover {
              &::after {
                background-color: array-get($config, input, skins, $skin, solid, bg, default);
              }
            }
          }
        }

        &.ng-dirty.ng-invalid::before,
        &.ng-touched.ng-invalid::before {
          background-color: array-get($config, input, skins, $skin, ko, border, default);
        }
      }
    }

    emma-slider,
    emma-multi-slider,
    emma-timeduration {
      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {
        .ngx-slider::before {
          background-color: array-get($config, input, skins, $skin, ko, border, default);
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emma-slider-base();

//== Component Skin
@include m-build-component--emma-slider-skin($m--forms);
