//
//** User Welcome
//

//== General

@mixin build-messaging() {

  .previewMessaging {
    @include transition(array-get($m-config-header, header, self, transition, general));
    text-align: center;
    position: sticky;
    top: 225px;

    > img {
      width: 100%;
      max-width: 250px;

      &.svgImage {
        min-width: 250px;
      }
    }
  }

  .m-header--fixed.m-header--minimize-on {

    .previewMessaging {
      @include transition(array-get($m-config-header, header, self, transition, hide));
      top: 160px;
    }
  }

  .col-pro-preview-wizard {

    .previewMessaging {
      max-width: 360px;
      width: 100%;

      .previewMessaging-smartphone-switch {
        margin-bottom: 20px;

        .btn.active {
          background: get-state-color(brand, base);
          color: get-state-color(brand, inverse) !important;
        }
      }

      .previewMessaging-smartphone {
        position: relative;
        padding: 63px 24px 0;

        .deviceOff {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 100;
          pointer-events: none;
          opacity: 0;
          transition: opacity .2s ease-in-out;
        }

        &.off {

          .deviceOff {
            opacity: 1;
          }
        }

        &.ios {

          .deviceOff {
            background-image: url("../../../assets/media/emma/img/wizard/Bg-iOS15-off.png");
          }
        }

        &.android {

          .deviceOff {
            background-image: url("../../../assets/media/emma/img/wizard/Bg-Android12-off.png");
          }
        }

        &.push {
          background-repeat: no-repeat;

          &.ios {
            background-image: url("../../../assets/media/emma/img/wizard/Bg-iOS15.png");

            .android {
              display: none !important;
            }

            .message {

              .notification {
                border-radius: 12px;
                background-color: rgba(255, 255, 255, 0.5);
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);

                .header {
                  padding-top: 0;

                  .logo {
                    height: 45px;
                    width: 45px;
                    margin: 10px 10px 10px 0;
                    border-radius: 6px;
                    padding: 7px;
                    background-color: get-state-color(emmadarkblue, base);

                    img {
                      filter: none;
                    }
                  }

                  .nameapp {
                    display: none;
                    text-transform: uppercase;
                  }

                  .content-header {
                    margin-right: 35px;

                    .text {
                      text-align: left;
                      position: relative;

                      .title,
                      .txt {
                        line-height: 1.2;
                        overflow: visible;
                        overflow-wrap: anywhere;
                        white-space: normal;
                      }
    
                      .title {
                        font-weight: get-font-weight(bolder);
                        margin-bottom: 4px;
                      }
                    }
                  }

                  .image {
                    display: block;
                    position: absolute;
                    width: 35px;
                    height: 35px;
                    top: 30px;
                    right: 10px;
                    flex-shrink: 0;

                    img {
                      display: block;
                      width: 35px;
                      height: 35px;
                      object-fit: cover;
                      object-position: center center;
                      border-radius: 3px;
                    }
                  }
                }

                .content {

                  .text,
                  .image {
                    display: none;
                  }
                }
              }

              .action {
                display: none;
                width: 75%;
                margin: 10px auto 0;

                > div {
                  position: relative;
                  background-color: rgba(255, 255, 255, 0.6);
                  -webkit-backdrop-filter: blur(10px);
                  backdrop-filter: blur(10px);
                  min-height: 41px;
                  padding: 10px 15px;
                  text-align: left;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 1px;

                  &.hasicon {
                    padding-right: 40px;
                  }

                  &:first-child {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                  }

                  &:last-child {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                  }

                  > i {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    font-size: 1.65rem;
                  }
                }
              }
            }

            &.expanded {

              .message {

                .notification {
                  background-color: rgba(255, 255, 255, .75);

                  .header {

                    &.imgloaded {
                      margin-bottom: 10px;
                    }

                    .logo {
                      height: 38px;
                      width: 38px;
                    }

                    .image {
                      display: none;
                    }
                  }

                  .content {
                    display: flex;
                    flex-direction: column;

                    .text,
                    .image {
                      display: none;
                      order: 2;
                      margin-top: 10px;
                      margin-bottom: 0;
                    }

                    .imagebig {
                      display: block;
                      margin-left: -12px;
                      margin-right: -12px;
                      margin-bottom: -12px;

                      img {
                        border-radius: 0;
                      }
                    }
                  }
                }

                .notification-close {
                  display: block;
                }

                .action {
                  display: block;
                }
              }
            }
          }

          &.android {
            background-image: url("../../../assets/media/emma/img/wizard/Bg-Android12.png");

            .ios {
              display: none !important;
            }

            .message {

              .notification {
                border-radius: 28px;
                background-color: rgba(255, 255, 255, 1);

                .header {
                  position: relative;
                  padding-top: 10px;
                  padding-bottom: 7px;

                  .logo {
                    flex-shrink: 0;
                  }

                  .nameapp,
                  .timeago {
                    display: none;
                  }

                  .content-header {
                    overflow: hidden;

                    .text {
                      text-align: left;
                      min-height: 20px;
                      overflow: hidden;
                      position: relative;

                      .title,
                      .txt {
                        line-height: 1.2;
                        min-height: 20px;
                        position: relative;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
    
                      .title {
                        font-weight: get-font-weight(bolder);
                        margin-bottom: 2px;
                      }
                    }
                  }

                  .image {
                    display: block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    right: 20px;
                    flex-shrink: 0;

                    img {
                      display: block;
                      width: 40px;
                      height: 40px;
                      object-fit: cover;
                      object-position: center center;
                      border-radius: 3px;
                    }
                  }

                  &.imgloaded {

                    .content-header {
                      margin-right: 50px;
                    }
                  }
                }

                .content {
                  // display: none;
                }
              }
            }
          }

          .clock,
          .date {
            font-family: Roboto;
            font-weight: 100;
            color: #fff;
          }

          .clock {
            font-size: 4rem;
            margin-bottom: -15px;
          }

          .date {
            font-size: 1.6rem;
            margin-bottom: 30px;

            &::first-letter {
              text-transform: capitalize;
            }
          }

          .message {
            position: relative;
            margin-bottom: 20px;

            .notification {
              overflow: hidden;
              padding: 10px 12px 12px;

              .header {
                display: flex;
                align-items: center;
                font-size: .95rem;

                .logo {
                  flex-shrink: 0;
                  height: 20px;
                  width: 20px;
                  border-radius: 30px;
                  padding: 3px;
                  background-color: #656C74;
                  margin-right: 10px;

                  img {
                    display: block;
                    filter: brightness(100);
                  }
                }

                .nameapp {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-right: 10px;
                }

                .timeago {

                  &.android {

                    span {
                      font-size: 0.95rem;
                      margin-right: 10px;
                    }
                  }

                  &.ios {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 0.85rem;
                    margin-left: auto;
                  }
                }

                .arrow {
                  margin-left: auto;
                  width: 20px;
                  flex-shrink: 0;
                  padding-left: 10px;

                  img {
                    width: 100%;
                  }
                }
              }

              .content {
                display: none;

                .text {
                  flex: 1;
                  font-size: .9rem;
                  text-align: left;
                  overflow: hidden;

                  .title,
                  .txt {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  .title {
                    font-weight: get-font-weight(bolder);
                    margin-bottom: 2px;
                  }
                }

                .image {
                  height: 35px;
                  width: 35px;
                  margin-left: 10px;

                  img {
                    display: block;
                    height: 35px;
                    width: 35px;
                    object-fit: cover;
                    border-radius: 8px;
                    object-position: center center;
                  }
                }

                .break {
                  display: none;
                  flex-basis: 100%;
                }

                .imagebig {
                  display: none;
                  flex-basis: 100%;

                  img {
                    display: block;
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                    object-position: center center;
                  }
                }
              }

              .action {
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                font-weight: get-font-weight(bolder);
                font-size: 0.9rem;
                margin-left: 15px;
                color: get-brand-color();

                > div {
                  margin-top: 10px;
                  max-width: 100%;
                  min-width: 50px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-align: left;
                  margin-right: 15px;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }

            .notification-close {
              display: none;
              position: absolute;
              height: 35px;
              width: 35px;
              border-radius: 100%;
              background-color: rgba(255, 255, 255, .75);
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              top: -45px;
              right: 0;

              img {
                width: 12px;
                padding-top: calc((35px / 2) - 6px);
              }
            }
          }

          .versionPhone {
            position: relative;
            background-color: #fff;
            border: 1px solid #fff;
            margin: 0 -24px;
            padding: 0 24px 24px;
            z-index: 150;

            &::before {
              position: absolute;
              content: '';
              top: -1px;
              left: -1px;
              right: -1px;
              height: 5px;
              background-color: get-state-color(altgreen, base);
            }

            &.A {

              &::before {
                background-color: get-state-color(altpink, base);
              }
            }

            &.B {

              &::before {
                background-color: get-state-color(altblue, base);
              }
            }

            &.C {

              &::before {
                background-color: get-state-color(altorange, base);
              }
            }

            .btnVersion {
              padding-top: 20px;
              padding-bottom: 20px;
              margin-left: -24px;
              margin-right: -24px;

              .versionText {
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
              }
            }

            .btnAction {
              margin-bottom: 24px;
            }

            p {
              color: get-color($skin, muted);
            }
          }

          &.expanded {

            &.android {

              .message {

                .notification {

                  .header {
                    padding-bottom: 15px;

                    .nameapp,
                    .timeago {
                      display: block;
                    }

                    .content-header {
                      display: none;
                    }

                    .image {
                      display: none;
                    }
                  }

                  .content {
                    display: flex;

                    .text {
                      overflow: visible;
                    }

                    .image {
                      display: none;
                    }
                  }
                }
              }
            }

            .message {

              .notification {

                .header {

                  .arrow {
                    transform: rotate3d(0, 0, 1, 180deg);
                    padding-right: 10px;
                    padding-left: 0;
                  }
                }

                .content {
                  flex-wrap: wrap;

                  .text {
                    height: auto;
                    max-height: 180px;
                    margin-bottom: 10px;

                    .title,
                    .txt {
                      line-height: 1.2;
                      overflow: visible;
                      overflow-wrap: anywhere;
                      white-space: normal;
                    }

                    .title {
                      margin-bottom: 4px;
                    }
                  }

                  .break {
                    display: block;
                  }

                  .imagebig {
                    display: block;
                    height: auto;
                    width: auto;
                    margin-left: 0;
                    max-height: 200px;
                    overflow: hidden;

                    img {
                      max-height: 200px;
                      height: auto;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .container-form-preview-wizard {

    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    .col-form-wizard {
      flex: 1;
      padding-right: 5rem;
      min-width: 0;
    }

    .col-preview-wizard {
      max-width: 300px;
    }

    .col-pro-preview-wizard {
      width: 360px;
    }
  }

  @include minimal-desktop-and-below {

    .container-form-preview-wizard {
      flex-direction: column;

      .col-form-wizard {
          padding-right: 0;
      }

      .col-pro-preview-wizard {
        width: 100%;

        .previewMessaging {
          margin: 0 auto;
        }
      }
    }
  }

  .emma-testab-container {

    .emma-testab-version-container {
      position: relative;
      padding-left: 20px;

      &::before {
        display: block;
        position: absolute;
        content: '';
        width: 3px;
        top: 12px;
        bottom: 0;
        left: 0;
        background-color: #000;
      }
    }

    .emma-testab-version-info {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      max-width: calc(100% + 29px);
      min-height: 30px;
      margin-bottom: 10px;
      margin-left: -29px;
      justify-content: space-between;

      i {
        margin-right: 10px;
      }

      .version-title {
        font-size: get-font-size(regular, '++');
        font-weight: get-font-weight(bolder);
        margin-bottom: 0;
        margin-right: 10px;
        flex-grow: 1;
      }

      button {
        min-width: fit-content;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .emma-testab--options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

        > .option-item {
          flex: 1 1 auto;
          // Add width 1% and flex-basis auto to ensure that button will not wrap out
          // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
          width: 1%;
          cursor: pointer;
          margin: 5px;
          min-width: 110px;
          max-width: 135px;
          @include border-radius(.25rem);
          @include transition(all .15s ease-in-out);

          span {
              color: get-color(light, regular);
              @include transition(all .15s ease-in-out);
          }

          .option-item-img {
              text-align: center;
              padding: 20px 50px 10px;

              > img {
                  width: 100%;
              }
          }

          .option-item-text {
              text-align: center;
              padding: 10px;

              > span:first-child {
                  display: block;
              }
          }

          &:hover {
              background-color: get-brand-color();
              color: get-brand-inverse-color();

              span,
              span[class^='m--font-'] {
                  color: get-brand-inverse-color() !important;
              }

              .option-item-img {

                  > img {
                      filter: brightness(0) invert(1);
                  }
              }
          }

          &:only-child {
              max-width: 180px;
          }
      }
    }
  }

  .containerBlock {

    .containerBlockHeader {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      min-height: 30px;
      margin-bottom: 10px;

      .containerBlockTitle {
        font-size: get-font-size(regular, '++');
        font-weight: get-font-weight(bolder);
        margin-bottom: 0;
        margin-right: 10px;
      }
    }

    .containerBlockContent {
      padding-top: 0;
      padding-bottom: 5px;

      ~ .containerBlockContent {
        padding-top: 5px;
      }
    }
  }

  @include minimal-desktop-and-below {

    .container-form-preview-wizard {

      .col-form-wizard {
        padding-right: 4rem;
      }
    }
  }

  //== Modals

  //== Audience Modal

  .audience-dialog-opts-container {

    .audience-dialog-opt {
      background-color: get-state-color(metal, base);
      @include border-radius(8px);
      padding: 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin-bottom: 10px;

      .audience-dialog-opt--title {
        font-size: get-font-size(regular, '++++');
        font-weight: get-font-weight(bolder);

        > i {
            font-size: get-font-size(icon, '++++');
            vertical-align: middle;
            margin-right: 10px;
            margin-bottom: 4px;
        }

      }

      .audience-dialog-opt--text {

      }

      &:hover {
        background-color: get-state-color(altgreen, base);
      }
    }
  }
}


  //== Component States

@mixin build-messaging--states() {

  .emma-testab-container {

    @each $name, $color in $m--state-colors {

      .emma-testab-version-container-#{$name} {

        &::before {
          background-color: array-get($color, base) !important;
        }
      }
		}
	}
}

//==== Generate Component ====//

//== Component General

  @include build-messaging();

  @include build-messaging--states();
