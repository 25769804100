//
//** "User Login - 1" Snippet
//

//== Snippet Base
@mixin m-build-snippet--login-1-base() {
  /* Login Base v2 */
  .m-login.m-login--emma.m-login--emma_fullpage {
    background-image: url('../../../assets/media/emma/img/login/EMMA-Poster_2020_web_dark.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5rem 5vw;
    justify-content: center;

    .m-login--emma_container {
      min-width: 400px;
    }

    .m-login__content {
      padding: 5rem 5vw;
      background-color: get-state-color(emmadarkblue, inverse);
      @include border-radius(8px);
      max-width: 1600px;
      align-self: center;

      > div:first-child {
        max-width: 100%;
      }

      &.simple-container {
        padding: 4rem 3rem;
      }

      .m-login--emma_side-left {
        .emma-form-context-header {
          margin-bottom: 2.2rem;

          .m-login__logo {
            text-align: center;
            margin-bottom: 2.2rem;

            img {
              max-width: 200px;
              width: 100%;
            }
          }

          p {
            font-size: 1.4rem;
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
          }
        }

        .emma-form-context-content {
          max-width: 500px;

          .emma-form-context-content_plan {
            margin-bottom: 2.2rem;

            > h3 {
              font-weight: 700;
              position: relative;
              padding-bottom: 10px;

              &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 80px;
                height: 3px;
                @include border-radius(8px);
              }

              &.altgreen::after {
                background-color: get-state-color(altgreen, base);
              }

              &.altpink::after {
                background-color: get-state-color(altpink, base);
              }

              &.altblue::after {
                background-color: get-state-color(altblue, base);
              }
            }

            > p {
              font-size: 1.4rem;
            }
          }

          .emma-form-context-payfeatures {
            display: flex;
            margin-bottom: 2rem;

            .emma-payfeature {
              flex: 1;
              text-align: center;

              img {
                margin-bottom: 1rem;
              }
            }
          }

          .emma-pay-explanation {
            font-style: italic;
            text-align: center;
            margin: 0;
          }

          .emma-divider-feature {
            height: 4px;
            border-radius: 10px;
            background-color: get-state-color(altgreen, base);
            width: 80%;
            margin: 3rem auto;
          }

          .emma-pay-powered {
            text-align: center;
            margin-bottom: 1rem;

            img {
              width: 100%;
              max-width: 175px;
            }
          }

          .emma-pay-creditcards {
            text-align: center;

            img {
              width: 100%;
              max-width: 210px;
            }
          }
        }

        .emma-form-context-footer {
          p {
            font-size: 1.4rem;
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      .m-login--emma_side-right {
        .emma-form-header {
          .m-login__logo {
            display: none;
            text-align: center;
            margin-bottom: 2.2rem;

            img {
              max-width: 140px;
              width: 100%;
            }

            &.login-layout {
              display: block;
            }
          }

          .m-login__desc {
            font-size: 1.4rem;
            text-align: right;
          }
        }

        .emma-form-content {
          .m-login__form {
            h3 {
              position: relative;
              font-size: 1.5rem;
              font-weight: 400;
              padding-bottom: 10px;

              &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 80px;
                height: 3px;
                background-color: get-state-color(altgreen, base);
                @include border-radius(8px);
              }
            }

            .emmaLoginMessages {
              p:last-child {
                margin-bottom: 0;
              }
            }

            .emma-price-plan {
              text-align: right;
              font-size: 2.2rem;

              .emma-price-plan--period {
                font-size: 1.2rem;
              }

              .emma-price-plan--include {
                display: block;
                font-size: 0.9rem;
              }
            }

            .emma-pay-card-preview {
              text-align: center;

              > p {
                font-style: italic;
                text-align: left;
                margin-top: 2rem;
                margin-bottom: 1rem;
              }

              .emma-pay-card-preview-card {
                display: inline-block;
                position: relative;

                .creditcardcompany {
                  position: absolute;
                  top: 40px;
                  right: 40px;
                }

                .emma-pay-card-preview-owner {
                  position: absolute;
                  top: 70px;
                  left: 60px;
                  right: 34px;
                  text-align: left;

                  .title {
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 700;
                    font-size: 0.6rem;
                  }

                  .content {
                    display: block;
                    color: get-state-color(altgreen, base);
                    font-size: 1.3rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .emma-pay-card-preview-number {
                  position: absolute;
                  top: 130px;
                  left: 60px;
                  right: 34px;
                  font-size: 1.5rem;
                  font-weight: 500;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: get-state-color(altgreen, base);

                  .content {
                    color: get-state-color(altgreen, base);
                    white-space: nowrap;
                  }
                }

                .emma-pay-card-preview-date {
                  position: absolute;
                  bottom: 50px;
                  left: 60px;
                  text-align: left;
                  display: flex;
                  align-items: center;

                  .title {
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 700;
                    font-size: 0.6rem;
                    width: 50px;
                    line-height: 1.2;
                    text-align: left;
                    display: inline-block;
                  }

                  .content {
                    color: get-state-color(emmadarkblue, base);
                    font-size: 1.4rem;
                    line-height: 1;
                    padding-top: 1px;
                  }
                }

                img.cardimage {
                  display: block;
                }
              }
            }

            .emma-form-samerow {
              display: flex;

              &.equal {
                > * {
                  flex: 1;

                  &:first-child {
                    margin-right: 10px;
                  }

                  &:last-child {
                    margin-left: 10px;
                  }
                }
              }

              &.lastsmall {
                > *:first-child {
                  flex: 1;
                  margin-right: 10px;
                }

                > *:last-child {
                  width: 210px;
                  margin-left: 10px;
                }
              }
            }

            .m-form__group {
              padding-top: 10px;
              padding-bottom: 10px;
              position: relative;

              label {
                font-size: 1.2rem;
              }

              .form-control {
                font-size: 1.1rem;
                @include border-radius(0);
                border: 0;
                background-color: array-get($m--forms, input, skins, light, solid, bg, disabled);
                border-bottom: 2px solid get-state-color(emmadarkblue, base);
                padding: 1rem;
                margin-top: 0.1rem;

                @include input-placeholder(#9496a0);

                &.m-login__form-input--last {
                  border: 0;
                }

                &:focus {
                  @include input-placeholder(get-state-color(brand));
                }

                &.ng-dirty.ng-invalid,
                &.ng-touched.ng-invalid {
                  color: get-state-color(danger);
                  @include input-placeholder(lighten(get-state-color(danger), 3%));
                }
              }

              &.has-danger {
                .form-control {
                  //color: get-state-color(danger);
                  //@include input-placeholder(lighten(get-state-color(danger), 3%));
                }

                .form-control-feedback {
                  font-weight: get-font-weight(bold);
                  font-size: get-font-size(regular, '-');
                }
              }

              emma-input {
                &.ng-dirty.ng-invalid,
                &.ng-touched.ng-invalid {
                  .form-control {
                    color: get-state-color(danger);
                    @include input-placeholder(lighten(get-state-color(danger), 3%));
                  }
                }
              }

              emma-select {
                .ng-select .ng-select-container {
                  font-size: 1.1rem;
                  @include border-radius(0);
                  border: 0;
                  background-color: array-get($m--forms, input, skins, light, solid, bg, disabled);
                  border-bottom: 2px solid get-state-color(emmadarkblue, base);
                  padding: 1rem;
                  margin-top: 0.1rem;

                  &::after {
                    display: none;
                  }

                  .ng-arrow-wrapper {
                    color: #9496a0;
                  }

                  .ng-placeholder {
                    color: #9496a0;
                  }

                  .ng-input {
                    top: 14px;
                    padding-left: 1rem;

                    > input {
                      padding-left: 0;
                      margin-left: -5px;
                    }
                  }

                  .ng-value {
                    color: get-state-color(emmadarkblue, base);
                  }
                }

                &.m-login__form-input--last {
                  .ng-select .ng-select-container {
                    border: 0;
                  }
                }

                &.ng-dirty.ng-invalid,
                &.ng-touched.ng-invalid {
                  .ng-placeholder {
                    color: lighten(get-state-color(danger), 3%);
                  }

                  .ng-value {
                    color: get-state-color(danger);
                  }
                }
              }
            }

            .m-login__form-sub {
              .m-checkbox {
                font-size: get-font-size(regular, '+');
              }

              .m-link {
                font-size: get-font-size(regular, '++');
              }
            }

            .m-login__form-action {
              margin: 2rem 0 2rem 0;
              text-align: right;

              .btn {
                display: inline-block;
                text-align: center;
                font-size: 1.2rem;
                padding: 1rem 2rem;
              }
            }

            .emma-form-social-login {
              display: flex;
              text-align: center;
              font-weight: 400;

              > span {
                margin: 0 auto;
                padding: 0 12px;
              }

              &::before,
              &::after {
                content: '';
                border-bottom: solid #E5E5E5 1px;
                flex: 1 auto;
                align-self: center;
              }
            }
          }
        }

        .emma-form-footer {
          .m-login__desc {
            font-size: 1.4rem;
            text-align: center;
            margin-top: 1rem;
          }
        }
      }

      .m-login--emma_divider {
        height: 80%;
        width: 4px;
        align-self: center;
        margin: 0 4vw;
        @include border-radius(10px);
        background-color: array-get($m--forms, input, skins, light, solid, bg, disabled);
      }
    }
  }

  @include tablet-and-mobile() {
    .m-login.m-login--emma.m-login--emma_fullpage {
      .m-login--emma_container {
        min-width: unset;
      }
      .m-login__content {
        align-self: auto;
        max-height: unset;
        max-width: 100%;

        &.simple-container {
          flex: 0 auto;
          align-self: center;
        }

        .m-login--emma_container.m-login--emma_container.m-login--emma_container {
          flex-direction: column;

          .m-login--emma_side-left {
            order: 2;

            .emma-form-context-header {
              display: none;
            }
          }

          .m-login--emma_divider {
            order: 1;
            height: 4px;
            width: 80%;
            margin: 4vw 0;
          }

          .m-login--emma_side-right {
            order: 0;

            .emma-form-header {
              .m-login__logo {
                display: block;
                text-align: center;
                margin-bottom: 2.2rem;

                img {
                  max-width: 140px;
                  width: 100%;
                }
              }

              .m-login__desc {
                text-align: center;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }

  @include mobile() {
    .m-login.m-login--emma.m-login--emma_fullpage {
      .m-login__content.m-login__content.m-login__content {
        flex-grow: inherit;

        .m-login--emma_container.m-login--emma_container.m-login--emma_container {
          .m-login--emma_side-left {
            display: none;
          }

          .m-login--emma_divider {
            display: none;
          }

          .m-login--emma_side-right {
            .m-login__form {
              .m-login__form-action {
                .btn {
                  margin-right: 0 !important;
                  margin-bottom: 10px !important;
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

//== Build Component Base
@include m-build-snippet--login-1-base();
